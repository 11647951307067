import { render, staticRenderFns } from "./AddPoints.vue?vue&type=template&id=75a7e6ed&scoped=true"
import script from "./AddPoints.vue?vue&type=script&lang=js"
export * from "./AddPoints.vue?vue&type=script&lang=js"
import style0 from "./AddPoints.vue?vue&type=style&index=0&id=75a7e6ed&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+com_7907ed7515d572276d45114f834f04a7/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75a7e6ed",
  null
  
)

export default component.exports