<template>
	<v-card class="elevation-10">
		<div class="pa-4 pa-sm-6">
			<v-dialog ref="dialog" v-model="modal" :return-value.sync="date" width="300">
				<template v-slot:activator="{ on, attrs }">
					<v-btn small icon :color="modal ? 'primary' : undefined" class="float-end" v-bind="attrs" v-on="on">
						<v-icon small> mdi-calendar-clock </v-icon>
					</v-btn>
				</template>
				<v-date-picker v-model="date" scrollable>
					<v-spacer></v-spacer>
					<v-btn text @click="modal = false"> Cancel </v-btn>
					<v-btn depressed color="primary" @click="filterDate"> Ok </v-btn>
				</v-date-picker>
			</v-dialog>
			<div class="d-flex flex-column">
				<div class="font-weight-bold">Transactions</div>
				<div class="grey--text mt-2">Transaction history list</div>
			</div>
		</div>

		<v-divider></v-divider>

		<v-data-table
			:hide-default-footer="true"
			loading-text="Loading, please wait"
			:headers="headers"
			:items="records"
			:items-per-page="item_per_page_value"
			:loading="recordsLoading"
			:sort-by.sync="sortBy"
			:sort-desc.sync="sortDesc"
			:page.sync="page"
			@page-count="page_count = $event">
			<template v-slot:item.transaction="{ item }">
				<span v-if="item.transaction == 'Win'">Won</span>
				<span v-else-if="item.transaction == 'Withdraw'">Withdrew Token</span>
				<span v-else>{{ item.transaction }}</span>
			</template>

			<template v-slot:item.points="{ item }">
				<v-icon v-if="[2, 3, 7].includes(parseInt(item.transaction_type_id))" x-small class="red--text">mdi-minus</v-icon>
				<v-icon v-else x-small class="success--text">mdi-plus</v-icon>
				<strong :class="[2, 3, 7].includes(parseInt(item.transaction_type_id)) ? 'red--text' : 'success--text'">{{
					moneyFormat(parseFloat(item.points))
				}}</strong>
			</template>

			<template v-slot:item.date="{ item }">
				<span>{{ formatTheDate(item.date) }}</span>
			</template>
		</v-data-table>

		<div v-if="records && records.length != 0">
			<v-divider></v-divider>

			<div class="d-flex align-center justify-space-between bs-gap-3 pa-4 pa-sm-6">
				<div class="d-flex align-center bs-gap-3">
					<v-select
						:value="item_per_page_value"
						:items="item_per_page"
						outlined
						dense
						label="Items"
						:hide-details="true"
						style="width: 100px"
						@change="item_per_page_value = parseInt($event, 10)"></v-select>
					<div>
						Page
						{{ page ? page : '0' + '-' + item_per_page_value ? item_per_page_value : '0' }}
						of {{ page_count ? page_count : '0' }}
					</div>
				</div>
				<v-pagination v-model="page" :length="page_count" :total-visible="0"></v-pagination>
			</div>
		</div>
	</v-card>
</template>

<script>
import formatDate from '@/helpers/formatDate'
import { mapState, mapActions } from 'vuex'

export default {
	data: () => ({
		today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
		date: '',
		menu: false,
		modal: false,

		headers: [
			{ text: 'Transaction', value: 'transaction', class: 'text-uppercase' },
			{ text: 'Token', value: 'points', class: 'text-uppercase' },
			{ text: 'Transaction Date', value: 'date', class: 'text-uppercase' },
		],

		sortBy: 'date',
		sortDesc: true,

		page: 1,
		page_count: 0,
		item_per_page: [5, 10, 20, 40, 80],
		item_per_page_value: 10,
	}),
	props: {
		account_status: {
			type: String,
			required: true,
		},
	},
	created() {
		this.$store.commit('transactions/setRecords', [])

		this.loadRecords()
	},
	computed: {
		...mapState('transactions', ['records', 'recordsLoading']),
		...mapState(['accountStatus']),
		dateFormatted() {
			if (!this.date) {
				return 'Filter'
			} else {
				if (this.date == this.today) {
					return 'Today'
				} else {
					return formatDate(this.date, 'date')
				}
			}
		},
		itemPerPage() {
			if (!this.date) {
				return -1
			} else {
				return 10
			}
		},
	},
	methods: {
		...mapActions('transactions', ['getRecords']),
		formatTheDate(theDate) {
			return formatDate(theDate)
		},
		moneyFormat(val) {
			return val.toLocaleString(undefined, {
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			})
		},
		filterDate() {
			this.$refs.dialog.save(this.date)

			this.loadRecords()
		},
		async loadRecords() {
			if (!this.recordsLoading) {
				const formData = new FormData()
				formData.append('date_start', this.date)

				const getData = new URLSearchParams(formData)

				await this.getRecords(getData)
			}
		},
	},
	watch: {
		accountStatus(val) {
			if (val) {
				this.loadRecords()
			}
		},
	},
}
</script>
