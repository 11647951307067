<template>
	<v-dialog v-model="dialog" :persistent="gcashLoading" scrollable max-width="400">
		<v-card class="bs-shadow-none">
			<div class="pa-4 pa-sm-6" style="overflow-y: auto">
				<v-btn small icon @click="setDialogs('gcash')" class="float-end">
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<h3 class="font-weight-bold mb-2">Add Token — GCash</h3>

				<div v-if="!success">
					<div class="grey--text mb-4 mb-sm-6">Enter or select token amount</div>
					<v-alert type="error" text class="rounded-lg mb-4" v-if="gcashError">
						{{ gcashError }}
					</v-alert>
					<!-- <v-alert type="info" text class="rounded-lg mb-4 mb-sm-6">
            1 token is equivalent to 1 peso
          </v-alert> -->

					<v-text-field
						v-model="points"
						:rules="[rules.required, minPoints]"
						label="Amount"
						:hint="minimumText"
						persistent-hint
						outlined
						dense
						:min="minimumPoints"
						:max="parseInt(actualPoints)"
						type="number"
						class="mb-4"></v-text-field>

					<v-btn-toggle class="d-block bs-bg-transparent">
						<div class="bs-row bs-g-2">
							<div class="bs-col-4" v-for="item in amountButtons" :key="item.id">
								<v-btn class="light" depressed block @click="selectAmount(item.amount)">
									{{ moneyFormat(parseFloat(item.amount)) }}
								</v-btn>
							</div>
						</div>
					</v-btn-toggle>

					<div class="d-flex justify-end bs-gap-2 mt-4 mt-sm-6">
						<v-btn text @click="setDialogs('gcash')" :disabled="gcashLoading">Cancel</v-btn>
						<v-btn depressed color="primary" :disabled="disableSubmitButton" @click="confirm_dialog = true">
							<span v-if="points">
								{{ `Proceed — ${points ? points.toLocaleString() : null}` }}
							</span>
							<span v-else>Proceed</span>
						</v-btn>
					</div>
				</div>
				<div v-else>
					<p class="grey--text mb-4 mb-sm-6">
						Click the button below to proceed to the payment gateway. The link will be opened in a new tab/window.
					</p>
					<v-btn block depressed color="primary" @click="openGcash"> Proceed </v-btn>

					<v-btn block depressed color="secondary" class="mt-3" @click="setDialogs('gcash')" v-if="displayClose"> Close </v-btn>
				</div>

				<v-dialog v-model="confirm_dialog" :persistent="confirm_dialog_loading" width="300">
					<v-card class="bs-shadow-none">
						<div class="pa-4 pa-sm-6">
							<h3 class="font-weight-bold mb-2">Confirm</h3>
							<div class="grey--text mb-4 mb-sm-6">
								You're about to pay
								{{ points ? points.toLocaleString() : null }} token, please confirm to proceed.
							</div>
							<div class="d-flex justify-end bs-gap-2">
								<v-btn text :disabled="confirm_dialog_loading" @click="confirm_dialog = false"> Cancel </v-btn>
								<v-btn :loading="confirm_dialog_loading" depressed class="primary" @click="handleClick"> Confirm </v-btn>
							</div>
						</div>
					</v-card>
				</v-dialog>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import formRules from '@/helpers/formRules'

export default {
	data: () => ({
		rules: formRules,
		points: null,
		// minimumPoints: 100,
		success: false,
		gcashLink: '',
		displayClose: false,
		confirm_dialog: false,
		confirm_dialog_loading: false,
	}),
	computed: {
		...mapState(['actualPoints']),
		...mapState('add_points', ['gcashError', 'gcashLoading', 'cashinAmounts']),
		dialog: {
			get: function () {
				return true
			},
			set: function () {
				this.setDialogs('gcash')
			},
		},
		disableSubmitButton() {
			if (this.points == null || this.points < this.minimumPoints) return true

			return false
		},
		minimumPoints() {
			if (this.cashinAmounts) {
				return this.cashinAmounts[0]['amount']
			} else {
				return 100
			}
		},
		minimumText() {
			if (this.cashinAmounts.length === 13) {
				return `Minimum Cash-In amount is Php${this.moneyFormat(this.minimumPoints)}`
			}

			return `New Player minimum cash-in is Php${this.moneyFormat(this.minimumPoints)}`
			// return `Minimum token amount to add is ${this.moneyFormat(
			//   parseFloat(this.cashinAmounts[0]["amount"])
			// )}.`;
		},
		amountButtons() {
			if (this.cashinAmounts.length === 13) {
				return this.cashinAmounts.slice(1)
			}

			return this.cashinAmounts
		},
	},
	methods: {
		...mapActions('add_points', ['submitGcash']),
		setDialogs(selectedDialog) {
			this.$emit('setDialogs', selectedDialog)
		},
		// minimumPoints(val) {
		//   if (val >= 100 && val <= 10000) {
		//     return true;
		//   } else {
		//     return "Minimum of 100 and maximum of 10,000 only.";
		//   }
		// },
		selectAmount(val) {
			this.points = val
		},
		async handleClick() {
			if (!this.gcashLoading) {
				this.confirm_dialog_loading = true

				const formData = new FormData()
				formData.append('token', this.points)

				const res = await this.submitGcash(formData)

				if (!this.gcashError) {
					this.gcashLink = res.gcash_url
					this.success = true
				}

				this.confirm_dialog_loading = false
				this.confirm_dialog = false
			}
		},
		openGcash() {
			this.displayClose = true
			window.open(this.gcashLink, '_blank')
		},
		moneyFormat(val) {
			return val.toLocaleString(undefined, {
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			})
		},
		minPoints(val) {
			if (val >= this.minimumPoints) {
				return true
			} else {
				if (this.cashinAmounts.length === 13) {
					return `Minimum Cash-In amount is Php${this.moneyFormat(this.cashinAmounts[0]['amount'])}`
				}

				return `New Player minimum cash-in is Php${this.moneyFormat(this.cashinAmounts[0]['amount'])}`

				// return `Minimum token amount to add is ${this.moneyFormat(
				//   parseFloat(this.minimumPoints)
				// )}.`;
			}
		},
	},
	watch: {
		points(val) {
			this.points = val ? parseFloat(val) : val
		},
	},
}
</script>

<style scoped>
.border {
	border: 2px dashed var(--v-primary-base);
}
</style>
