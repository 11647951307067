<template>
	<div class="game-cards">
		<div class="bs-row bs-gx-3 bs-gy-4">
			<div class="game-card bs-col-sm-6 bs-col-xl-4" v-for="game in games" :key="game.game_id" :id="game.game_id">
				<v-card class="card dark overflow-visible elevation-10 mt-2" style="position: relative">
					<div class="px-4 px-sm-6" style="position: absolute; top: -1rem; right: 0; left: 0">
						<div
							:class="$vuetify.theme.dark ? 'light' : 'dark'"
							class="rounded-lg elevation-1 d-flex justify-space-between bs-gap-4 px-4 py-3">
							<span class="font-weight-bold">{{ trimString(game.league) }}</span>
							<span v-if="game.game_status_id === null" class="grey--text"> Not Started </span>
							<span v-else-if="game.game_status_id == 1" class="d-flex align-center bs-gap-2 grey--text">
								<img :src="require('@/assets/chineseNewYearTheme2025/snake.gif')" height="18" class="d-inline" />
								Ongoing ∙ 1st Q
							</span>
							<span v-else-if="game.game_status_id == 2" class="d-flex align-center bs-gap-2 grey--text">
								<img :src="require('@/assets/chineseNewYearTheme2025/snake.gif')" height="18" class="d-inline" />
								Ongoing ∙ 2nd Q
							</span>
							<span v-else-if="game.game_status_id == 3" class="d-flex align-center bs-gap-2 grey--text">
								<img :src="require('@/assets/chineseNewYearTheme2025/snake.gif')" height="18" class="d-inline" />
								Ongoing ∙ 3rd Q
							</span>
							<span v-else-if="game.game_status_id == 4" class="d-flex align-center bs-gap-2 grey--text">
								<img :src="require('@/assets/chineseNewYearTheme2025/snake.gif')" height="18" class="d-inline" />
								Ongoing ∙ 4th Q
							</span>
							<span v-else class="grey--text">Game Ended</span>
						</div>
					</div>
					<div class="pa-4 pa-sm-6">
						<div class="jackpot-prize white--text text-center mt-6 mt-sm-4">
							<span class="grey--text">Jackpot Prize</span>
							<h1 class="secondary--text">
								{{ moneyFormat(parseFloat(game.jackpot_prize)) }}
							</h1>
						</div>
					</div>
					<div class="px-4 px-sm-6 pb-4 pb-sm-6">
						<div class="d-flex align-center bs-gap-3">
							<div class="d-inline-flex">
								<v-avatar size="64" class="light rounded-lg pa-3 me-3">
									<v-img :src="game.team_a_logo" :alt="game.team_a_name" contain> </v-img>
								</v-avatar>
								<v-avatar size="64" class="light rounded-lg pa-3">
									<v-img :src="game.team_b_logo" :alt="game.team_b_name" contain> </v-img>
								</v-avatar>
							</div>
							<div style="width: 100% !important">
								<div class="d-flex flex-column align-center text-center">
									<span> {{ trimString(game.team_a_name + ' ' + game.team_a_alias) }}</span>
									<span class="grey--text">vs.</span>
									<span> {{ trimString(game.team_b_name + ' ' + game.team_b_alias) }}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="px-4 px-sm-6 pb-4 pb-sm-6">
						<div class="d-flex justify-space-between align-end bs-gap-4">
							<span class="grey--text">{{ formatTheDate(game.schedule) }}</span>
							<div style="width: 145px">
								<v-btn
									v-if="game.game_status_id === null"
									depressed
									block
									color="primary"
									class="justify-space-between"
									@click="setDialog(game)">
									Play Now
									<div
										v-if="game.bet_count > 0"
										class="d-flex align-center"
										style="position: absolute; top: 0; right: 1rem; bottom: 0">
										<v-badge inline :content="game.bet_count" color="green"></v-badge>
									</div>
									<v-icon v-else>mdi-arrow-right-thin</v-icon>
								</v-btn>
								<v-btn v-else depressed block class="light justify-space-between" @click="setDialog(game)">
									View Card
									<div
										v-if="game.bet_count > 0"
										class="d-flex align-center"
										style="position: absolute; top: 0; right: 1rem; bottom: 0">
										<v-badge inline :content="game.bet_count" color="green"></v-badge>
									</div>
									<v-icon v-else>mdi-arrow-right-thin</v-icon>
								</v-btn>
							</div>
						</div>
					</div>
				</v-card>
			</div>
		</div>

		<v-dialog v-if="dialog" v-model="dialog" scrollable max-width="450">
			<v-card class="elevation-0">
				<div style="overflow-y: auto">
					<div class="d-flex justify-space-between align-center px-4 px-sm-6 pt-4 pt-sm-6">
						<h3 class="font-weight-bold ms-2">Details</h3>
						<v-btn icon depressed small @click="dialog = false">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</div>
					<BetGame class="bet-game" :game="game" @betConfirmed="betConfirmed" />
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import BetGame from '@/components/app/lucky_four/BetGame'
import formatDate from '@/helpers/formatDate'

export default {
	components: {
		BetGame,
	},

	props: {
		games: {
			required: true,
		},
	},

	data: () => ({
		dialog: false,
		game: {},
	}),

	methods: {
		moneyFormat(val) {
			return val.toLocaleString(undefined, {
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			})
		},

		setDialog(game) {
			this.game = game
			this.dialog = true
		},

		betConfirmed(val) {
			// Add My Bet
			const myBets = this.games.find((game) => game.game_id == val)
			myBets.bet_count = +myBets.bet_count + 1

			// Add Jackpot Prize
			// const amountAdded =
			//   (myBets.percentage_deduction / 100) * myBets.bet_amount;
			// myBets.jackpot_prize = +myBets.jackpot_prize + amountAdded;
		},
		trimString(team) {
			let index

			if (this.$vuetify.breakpoint.mdAndUp) {
				index = 30
			} else if (this.$vuetify.breakpoint.smAndUp) {
				index = 22
			}

			if (team.length > index) {
				return team.substring(0, index) + ' ...'
			} else {
				return team
			}
		},
		formatTheDate(theDate) {
			return formatDate(theDate)
		},
	},
}
</script>

<style scoped>
.jackpot-prize h1 {
	font-family: 'Sigmar', cursive;
	font-size: 30px;
	line-height: 1;
}

@media (width < 576px) {
	.game-card .card {
		border-radius: 0 !important;
	}
}
</style>
