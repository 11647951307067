<template>
	<div v-if="appData">
		<v-form ref="form" @submit.prevent="handleSubmit">
			<div class="font-weight-bold mb-6">Personal Information</div>
			<div class="d-block mb-6 mb-md-8">
				<div class="bs-row bs-g-3">
					<div class="bs-col-6">
						<v-text-field
							ref="username"
							v-model="username"
							:rules="[rules.required, rules.minimum(2), rules.maximum(25)]"
							label="Username"
							outlined
							dense
							hide-details="auto"></v-text-field>
						<!-- :disabled="accountStatus == 'Verified'" -->
					</div>
					<div class="bs-col-6">
						<v-text-field
							ref="first_name"
							v-model="firstname"
							:rules="[rules.required, rules.alphaSpace, rules.minimum(2), rules.maximum(50)]"
							label="First Name"
							outlined
							dense
							:disabled="accountStatus == 'Verified' && appData.account_details.first_name != null"
							hide-details="auto"
							:error-messages="validationError['first_name']"></v-text-field>
					</div>
					<div class="bs-col-6">
						<v-text-field
							ref="middle_name"
							v-model="middlename"
							:rules="[rules.alphaSpace, rules.minimum(2), rules.maximum(50)]"
							label="Middle Name"
							outlined
							dense
							:disabled="accountStatus == 'Verified' && appData.account_details.middle_name != ''"
							hide-details="auto"
							:error-messages="validationError['middle_name']"></v-text-field>
					</div>
					<div class="bs-col-6">
						<v-text-field
							ref="last_name"
							v-model="lastname"
							:rules="[rules.required, rules.alphaSpace, rules.minimum(2), rules.maximum(50)]"
							label="Last Name"
							outlined
							dense
							:disabled="accountStatus == 'Verified' && appData.account_details.last_name != null"
							hide-details="auto"
							:error-messages="validationError['last_name']"></v-text-field>
					</div>
					<div class="bs-col-6">
						<v-select
							ref="gender"
							v-model="selectedGender"
							:items="gender"
							:rules="[rules.required]"
							label="Gender"
							outlined
							dense
							hide-details="auto"
							:error-messages="validationError['gender']"></v-select>
						<!-- :disabled="
                accountStatus == 'Verified' &&
                appData.account_details.gender != null
              " -->
					</div>
					<div class="bs-col-6">
						<v-autocomplete
							ref="marital_status"
							v-model="selectedMaritalStatus"
							:items="maritalStatus"
							:rules="[rules.required]"
							label="Marital Status"
							outlined
							dense
							hide-details="auto"
							:error-messages="validationError['marital_status']"></v-autocomplete>
						<!-- :disabled="
                accountStatus == 'Verified' &&
                appData.account_details.marital_status != null
              " -->
					</div>
					<div class="bs-col-6">
						<v-menu ref="birthdate_menu" v-model="birthdate_menu" :close-on-content-click="false" offset-y left min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									ref="birthdate"
									v-model="birthdate"
									:rules="[rules.required, isUnder21]"
									label="Date of Birth"
									outlined
									dense
									:disabled="accountStatus == 'Verified' && appData.account_details.birthdate != ''"
									v-bind="attrs"
									v-on="on"
									@keyup="birthdate_menu = true"
									@keydown="birthdate_menu = false"
									hide-details="auto"
									:error-messages="validationError['birthdate']"></v-text-field>
							</template>
							<!-- :max="maxBirthdate()"
                :min="minBirthdate()" -->
							<v-date-picker
								v-model="birthdate"
								:active-picker.sync="birthdateActivePicker"
								:max="birthdate_max"
								no-title
								@change="$refs.birthdate_menu.save(birthdate)"></v-date-picker>
						</v-menu>
					</div>
					<div class="bs-col-6">
						<v-text-field
							ref="birthplace"
							v-model="birthplace"
							:rules="[rules.required]"
							label="Place of Birth"
							outlined
							dense
							hide-details="auto"
							:error-messages="validationError['birthplace']">
						</v-text-field>
						<!-- :disabled="
                accountStatus == 'Verified' &&
                appData.account_details.birthplace != ''
              " -->
					</div>
					<div class="bs-col-6">
						<v-autocomplete
							ref="nationality"
							v-model="nationality"
							:items="nationalities"
							:rules="[rules.required]"
							label="Nationality"
							outlined
							dense
							:disabled="accountStatus == 'Verified' && appData.account_details.nationality != null"
							item-text="nationality"
							item-value="id"
							hide-details="auto"
							:error-messages="validationError['nationality']"></v-autocomplete>
					</div>
					<div :class="source_of_income == '0' ? 'bs-col-12' : 'bs-col-6'">
						<v-autocomplete
							ref="source_of_income"
							v-model="source_of_income"
							:items="sourceOfIncome"
							:rules="[rules.required]"
							label="Source of Income"
							outlined
							dense
							item-text="source"
							item-value="id"
							hide-details="auto"
							:error-messages="validationError['source_of_income']"></v-autocomplete>
						<!-- :disabled="
                accountStatus == 'Verified' &&
                appData.account_details.source_of_income != '0'
              " -->
					</div>
					<div class="bs-col-6" v-if="source_of_income == 1">
						<v-autocomplete
							ref="nature_of_work"
							v-model="nature_of_work"
							:items="natureOfWork"
							:rules="[rules.required, validateNatureOfWork, validateSourceOfIncome]"
							label="Nature of Work"
							outlined
							dense
							item-text="nature"
							item-value="id"
							hide-details="auto"
							:error-messages="validationError['nature_of_work']"></v-autocomplete>
						<!-- :disabled="
                accountStatus == 'Verified' &&
                appData.account_details.nature_of_work != '0'
              " -->
					</div>

					<div class="bs-col-6" v-if="source_of_income == 2">
						<v-autocomplete
							ref="nature_of_business"
							v-model="nature_of_business"
							:items="natureOfBusiness"
							:rules="[rules.required, validateNatureOfBusiness]"
							label="Nature of Business"
							outlined
							dense
							item-text="nature"
							item-value="id"
							hide-details="auto"
							:error-messages="validationError['nature_of_business']"></v-autocomplete>
						<!-- :disabled="
                accountStatus == 'Verified' &&
                appData.account_details.nature_of_business != '0'
              " -->
					</div>
					<div :class="source_of_income == 1 || source_of_income == 2 ? 'bs-col-6' : 'bs-col-12'">
						<v-text-field
							ref="email_address"
							v-model="email_address"
							:rules="[, rules.validEmail]"
							label="Email Address"
							outlined
							dense
							type="email"
							hide-details="auto"
							:disabled="accountStatus == 'Verified' && appData.account_details.email_address != ''"
							:error-messages="validationError['email_address']"></v-text-field>
					</div>
				</div>
			</div>

			<div class="font-weight-bold mb-6">Current Address</div>

			<div class="d-block mb-4 mb-sm-6 mb-md-8">
				<div class="bs-row bs-g-3">
					<div class="bs-col-12">
						<v-text-field
							ref="address"
							v-model="address"
							:rules="[rules.required, rules.minimum(5), rules.maximum(200)]"
							label="Street, House/Lot & Block #, Room/Floor/Unit #"
							outlined
							hide-details="auto"
							dense
							:error-messages="validationError['address']"></v-text-field>
					</div>
					<div class="bs-col-sm-6">
						<v-autocomplete
							v-model="citymun"
							:items="citymuns"
							:rules="[rules.required]"
							label="City/Municipality"
							outlined
							dense
							item-value="id"
							item-text="citymun"
							hide-details="auto"></v-autocomplete>
					</div>
					<div class="bs-col-sm-6">
						<v-autocomplete
							v-model="barangay"
							ref="barangay_id"
							:items="filteredBarangays"
							:rules="[rules.required]"
							label="Barangay"
							outlined
							dense
							item-value="id"
							item-text="barangay"
							hide-details="auto"
							:error-messages="validationError['barangay_id']"></v-autocomplete>
					</div>
				</div>
			</div>
			<v-checkbox v-model="checkbox" hide-details="true" class="mt-0 pt-0 mb-3">
				<template v-slot:label>
					<div style="margin-top: 0.5px">Same as current address</div>
				</template>
			</v-checkbox>

			<div class="d-block">
				<div class="font-weight-bold mb-6">Permanent Address</div>
				<div class="bs-row bs-g-3">
					<div class="bs-col-12">
						<v-text-field
							ref="curr_address"
							v-model="curr_address"
							:rules="[rules.required, rules.minimum(5), rules.maximum(200)]"
							label="Street, House/Lot & Block #, Room/Floor/Unit #"
							outlined
							hide-details="auto"
							:disabled="checkbox"
							dense
							:error-messages="validationError['curr_address']"></v-text-field>
					</div>
					<div class="bs-col-sm-6">
						<v-autocomplete
							v-model="curr_citymun"
							:items="curr_citymuns"
							:rules="[rules.required]"
							label="City/Municipality"
							outlined
							dense
							item-value="id"
							item-text="citymun"
							hide-details="auto"
							:disabled="checkbox"></v-autocomplete>
					</div>
					<div class="bs-col-sm-6">
						<v-autocomplete
							ref="curr_barangay_id"
							v-model="curr_barangay"
							:items="filteredCurrentBarangays"
							:rules="[rules.required]"
							label="Barangay"
							outlined
							dense
							item-value="id"
							item-text="barangay"
							hide-details="auto"
							:disabled="checkbox"
							:error-messages="validationError['curr_barangay_id']"></v-autocomplete>
					</div>
				</div>
			</div>

			<!-- <div class="font-weight-bold mb-6">Current Address</div>

      <div class="d-block mb-6 mb-md-8">
        <div class="bs-row bs-g-3">
          <div class="bs-col-12">
            <v-text-field
              v-model="address"
              :rules="[rules.required, rules.minimum(5), rules.maximum(200)]"
              label="Street, House/Lot & Block #, Room/Floor/Unit #"
              outlined
              hide-details="auto"
              dense
            ></v-text-field>
          </div>
          <div class="bs-col-sm-6">
            <v-autocomplete
              v-model="citymun"
              :items="citymuns"
              :rules="[rules.required]"
              label="City/Municipality"
              outlined
              dense
              item-text="citymun"
              return-object
              hide-details="auto"
            ></v-autocomplete>
          </div>
          <div class="bs-col-sm-6">
          {{ barangay }}
            <v-autocomplete
              v-model="barangay"
              :items="filteredBarangays"
              :rules="[rules.required]"
              label="Barangay"
              outlined
              dense
              item-text="barangay"
              return-object
              hide-details="auto"
            ></v-autocomplete>
          </div>
        </div>
      </div>

      <v-checkbox v-model="checkbox" hide-details="true" class="mt-0 pt-0 mb-3">
        <template v-slot:label>
          <div style="margin-top: 1.5px">Same as current address</div>
        </template>
      </v-checkbox>
      <div class="d-block">
        <div class="font-weight-bold mb-6">Permanent Address</div>
        <div class="bs-row bs-g-3">
          <div class="bs-col-12">
            <v-text-field
              v-model="curr_address"
              :rules="[rules.required, rules.minimum(5), rules.maximum(200)]"
              label="Street, House/Lot & Block #, Room/Floor/Unit #"
              outlined
              hide-details="auto"
              :disabled="checkbox"
              dense
            ></v-text-field>
          </div>
          <div class="bs-col-sm-6">
            <v-autocomplete
              v-model="curr_citymun"
              :items="citymuns"
              :rules="[rules.required]"
              label="City/Municipality"
              outlined
              dense
              item-text="citymun"
              return-object
              :disabled="checkbox"
              hide-details="auto"
            ></v-autocomplete>
          </div>
          <div class="bs-col-sm-6">
            <v-autocomplete
              v-model="curr_barangay"
              :items="filteredCurrentBarangays"
              :rules="[rules.required]"
              label="Barangay"
              outlined
              dense
              item-text="barangay"
              return-object
              :disabled="checkbox"
              hide-details="auto"
            ></v-autocomplete>
          </div>
        </div>
      </div> -->

			<div class="d-flex justify-end mt-4 mt-sm-6">
				<v-btn block depressed color="primary" type="submit" :disabled="personal_info_loading" :loading="personal_info_loading"> Save </v-btn>
			</div>
		</v-form>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
// import { isObjectEmpty } from "@/helpers/form";
import nationalities from '@/data/nationalities.json'
import sourceOfIncome from '@/data/sourceOfIncome.json'
import natureOfWork from '@/data/natureOfWork.json'
import natureOfBusiness from '@/data/natureOfBusiness.json'
import citymuns from '@/data/citymuns.json'
import curr_citymuns from '@/data/citymuns.json'
import barangays from '@/data/barangays.json'
import curr_barangays from '@/data/barangays.json'
import formRules from '@/helpers/formRules'
import { format, subYears } from 'date-fns'
import responsePost from '@/helpers/api_request_post.js'

export default {
	data: () => ({
		rules: formRules,
		today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
		username: '',
		firstname: '',
		middlename: '',
		lastname: '',
		gender: ['Male', 'Female'],
		selectedGender: '',
		maritalStatus: ['Single', 'Married', 'Widowed', 'Divorced'],
		selectedMaritalStatus: '',
		birthdateActivePicker: null,
		birthdate: null,
		birthdate_menu: false,
		birthdate_max: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
		birthplace: '',
		nationality: '',
		nationalities: nationalities,
		source_of_income: null,
		sourceOfIncome: sourceOfIncome,
		nature_of_work: null,
		natureOfWork: natureOfWork,
		nature_of_business: null,
		natureOfBusiness: natureOfBusiness,

		// occupation: "",
		citymuns: citymuns,
		curr_citymuns: curr_citymuns,
		citymun: 0,
		curr_citymun: 0,
		barangays: barangays,
		curr_barangays: curr_barangays,
		barangay: 0,
		curr_barangay: 0,
		address: '',
		curr_address: '',
		email_address: '',
		emailAddressExistText: '' /* ,
    successAlert: false */,
		checkbox: false,
		validationError: [],
		personal_info_loading: false,
	}),
	computed: {
		...mapState(['appData', 'accountStatus']),
		...mapState('account', ['personalInfoError', 'personalInfoErrors', 'personalInfoLoading']),
		filteredBarangays() {
			if (!this.citymun) return []
			return this.barangays.filter((barangay) => barangay.citymun_id === this.citymun)
		},
		filteredCurrentBarangays() {
			if (!this.curr_citymun) return []
			return this.curr_barangays.filter((barangay) => barangay.citymun_id === this.curr_citymun)
		},
		validateNatureOfWork() {
			if (this.nature_of_work == '0') {
				return 'This field is required'
			} else {
				return true
			}
		},
		validateNatureOfBusiness() {
			if (this.nature_of_business == '0') {
				return 'This field is required'
			} else {
				return true
			}
		},
		validateSourceOfIncome() {
			if (this.nature_of_work == 1) {
				return 'Government officials or employees are not allowed.'
			} else {
				return true
			}
		},
	},
	mounted() {
		if (this.appData) {
			this.loadInfo()
			this.checkCurrentAndPermanentAddress()
		}
	},
	methods: {
		...mapActions('account', ['updatePersonalInfo']),
		maxBirthdate() {
			return format(subYears(new Date(this.today), 21), 'yyyy-MM-dd')
		},
		minBirthdate() {
			return format(subYears(new Date(this.today), 100), 'yyyy-MM-dd')
		},
		emailAddressExist(val) {
			if (val == this.emailAddressExistText) {
				return `"${val}" already exist`
			} else {
				return true
			}
		},
		loadInfo() {
			this.username = this.appData.account_details.username
			this.firstname = this.appData.account_details.first_name
			this.middlename = this.appData.account_details.middle_name ? this.appData.account_details.middle_name : ''
			this.lastname = this.appData.account_details.last_name
			this.selectedGender = this.appData.account_details.gender ?? ''
			this.selectedMaritalStatus = this.appData.account_details.marital_status ?? ''
			this.birthdate = this.appData.account_details.birthdate ? this.appData.account_details.birthdate : null
			this.birthplace = this.appData.account_details.birthplace ? this.appData.account_details.birthplace : null
			this.nationality = parseInt(this.appData.account_details.nationality) > 0 ? parseInt(this.appData.account_details.nationality) : 175
			this.source_of_income =
				parseInt(this.appData.account_details.source_of_income) > 0 ? parseInt(this.appData.account_details.source_of_income) : ''
			this.nature_of_work =
				parseInt(this.appData.account_details.nature_of_work) > 0 ? parseInt(this.appData.account_details.nature_of_work) : ''
			this.nature_of_business =
				parseInt(this.appData.account_details.nature_of_business) > 0 ? parseInt(this.appData.account_details.nature_of_business) : ''
			this.email_address = this.appData.account_details.email_address
			this.address = this.appData.account_details.address
			this.curr_address = this.appData.account_details.curr_address
			this.citymun = JSON.parse(this.appData.account_details.city_mun).id ? JSON.parse(this.appData.account_details.city_mun).id : 0
			this.curr_citymun = JSON.parse(this.appData.account_details.curr_city_mun).id
				? JSON.parse(this.appData.account_details.curr_city_mun).id
				: 0
			this.barangay = JSON.parse(this.appData.account_details.barangay).id ? JSON.parse(this.appData.account_details.barangay).id : 0
			this.curr_barangay = JSON.parse(this.appData.account_details.curr_barangay).id
				? JSON.parse(this.appData.account_details.curr_barangay).id
				: 0

			this.$refs.form.validate()
		},
		// async handleSubmit() {
		//   if (this.$refs.form.validate() && !this.personalInfoLoading) {
		//     const formData = new FormData();
		//     formData.append("username", this.username);
		//     formData.append("first_name", this.firstname);
		//     formData.append("middle_name", this.middlename);
		//     formData.append("last_name", this.lastname);
		//     formData.append("gender", this.selectedGender);
		//     formData.append("marital_status", this.selectedMaritalStatus);
		//     formData.append("birthdate", this.birthdate);
		//     formData.append("birthplace", this.birthplace);
		//     formData.append("nationality", this.nationality);
		//     formData.append("source_of_income", this.source_of_income);
		//     formData.append("nature_of_work", this.nature_of_work);
		//     formData.append("nature_of_business", this.nature_of_business);
		//     formData.append("barangay_id", this.barangay.id);
		//     formData.append("address", this.address);
		//     formData.append("curr_barangay_id", this.curr_barangay.id);
		//     formData.append("curr_address", this.curr_address);
		//     formData.append("email_address", this.email_address);

		//     const res = await this.updatePersonalInfo(formData);

		//     if (!this.personalInfoError && isObjectEmpty(this.personalInfoErrors)) {
		//       this.appData.account_details.first_name = this.firstname;
		//       this.appData.account_details.middle_name = this.middlename;
		//       this.appData.account_details.last_name = this.lastname;
		//       this.appData.account_details.gender = this.selectedGender;
		//       this.appData.account_details.marital_status = this.selectedMaritalStatus;
		//       this.appData.account_details.birthdate = this.birthdate;
		//       this.appData.account_details.nationality = this.nationality;
		//       this.appData.account_details.source_of_income = this.source_of_income;
		//       // this.appData.account_details.occupation = this.occupation;
		//       this.appData.account_details.address = this.address;
		//       this.appData.account_details.email_address = this.email_address;
		//       this.appData.account_details.city_mun = JSON.stringify(this.citymun);
		//       this.appData.account_details.barangay = JSON.stringify(this.barangay);
		//       this.appData.account_details.curr_citymun = JSON.stringify(this.curr_citymun);
		//       this.appData.account_details.curr_barangay = JSON.stringify(this.curr_barangay);

		//       if (res.completed_verification) {
		//         if (this.appData.account_details.account_verified != "Verified") {
		//           this.$store.commit("setAccountStatus", "Files Submitted");
		//           this.appData.account_details.account_verified = "Files Submitted";
		//         }

		//         this.$emit("verifySubmitted");
		//       }

		//       this.$emit("personalInfoCompleted");
		//     } else {
		//       if (!isObjectEmpty(this.personalInfoErrors)) {
		//         let errorFocus = "";

		//         Object.entries(this.personalInfoErrors).forEach(([key, value]) => {
		//           if (value) {
		//             if (!errorFocus) {
		//               errorFocus = key;
		//             }

		//             if (key == "email_address") {
		//               this.emailAddressExistText = this.email_address;
		//             }
		//           }
		//         });

		//         this.$refs.form.validate();

		//         this.$refs[errorFocus].focus();
		//       }
		//     }
		//   }
		// },
		async handleSubmit() {
			if (this.$refs.form.validate() && !this.personal_info_loading) {
				this.personal_info_loading = true
				this.validationError = []

				try {
					const formData = new FormData()
					formData.append('username', this.username)
					formData.append('first_name', this.firstname)
					formData.append('middle_name', this.middlename)
					formData.append('last_name', this.lastname)
					formData.append('gender', this.selectedGender)
					formData.append('marital_status', this.selectedMaritalStatus)
					formData.append('birthdate', this.birthdate)
					formData.append('birthplace', this.birthplace)
					formData.append('nationality', this.nationality)
					formData.append('source_of_income', this.source_of_income)
					formData.append('nature_of_work', this.nature_of_work)
					formData.append('nature_of_business', this.nature_of_business)
					formData.append('barangay_id', this.barangay)
					formData.append('address', this.address)
					formData.append('curr_barangay_id', this.curr_barangay)
					formData.append('curr_address', this.curr_address)
					formData.append('email_address', this.email_address)

					const res = await responsePost(formData, 'account/update_personal_info')

					if (res.status == 200) {
						this.appData.account_details.first_name = this.firstname
						this.appData.account_details.middle_name = this.middlename
						this.appData.account_details.last_name = this.lastname
						this.appData.account_details.gender = this.selectedGender
						this.appData.account_details.marital_status = this.selectedMaritalStatus
						this.appData.account_details.birthdate = this.birthdate
						this.appData.account_details.birth = this.birthdate
						this.appData.account_details.nationality = this.nationality
						this.appData.account_details.address = this.address
						this.appData.account_details.email_address = this.email_address
						this.appData.account_details.city_mun = JSON.stringify(this.citymun)
						this.appData.account_details.barangay = JSON.stringify(this.barangay)
						this.appData.account_details.curr_citymun = JSON.stringify(this.curr_citymun)
						this.appData.account_details.curr_barangay = JSON.stringify(this.curr_barangay)

						if (res.completed_verification) {
							if (this.appData.account_details.account_verified != 'Verified') {
								this.$store.commit('setAccountStatus', 'Files Submitted')
								this.appData.account_details.account_verified = 'Files Submitted'
							}

							this.$emit('verifySubmitted')
						}

						this.$emit('personalInfoCompleted')
						location.reload()
					} else if (res.status == 422) {
						this.validationError = res.data.messages

						const firstNonEmptyKey = Object.keys(this.validationError).find((key) => {
							const value = this.validationError[key]
							return value !== null && value !== undefined && value !== ''
						})

						this.$refs[firstNonEmptyKey].focus()
					}
				} catch (error) {
					console.error(error)
				}

				this.personal_info_loading = false
			}
		},
		isUnder21() {
			var today = new Date()
			var birthdateObj = new Date(this.birthdate)
			var age = today.getFullYear() - birthdateObj.getFullYear()
			var monthDiff = today.getMonth() - birthdateObj.getMonth()

			if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdateObj.getDate())) {
				age--
			}

			if (age < 21) {
				return 'Age under 21 years old is restricted.'
			} else if (age >= 21) {
				return true
			}
		},
		checkCurrentAndPermanentAddress() {
			if (this.address == this.curr_address && this.citymun.id == this.curr_citymun.id && this.barangay.id == this.curr_barangay.id) {
				this.checkbox = true
			}
		},
	},
	watch: {
		birthdate_menu(val) {
			val && setTimeout(() => (this.birthdateActivePicker = 'YEAR'))
		},
		source_of_income(val) {
			if (val == 1) {
				this.nature_of_business = '0'
			} else if (val == 2) {
				this.nature_of_work = '0'
			} else {
				this.nature_of_business = '0'
				this.nature_of_work = '0'
			}
		},
		appData(val) {
			console.log('tetst', val)
			if (val) {
				this.loadInfo()
			}
		},
		checkbox(val) {
			if (val) {
				this.curr_barangay = this.barangay
				this.curr_citymun = this.citymun
				this.curr_address = this.address
			} else {
				this.curr_barangay = 0
				this.curr_citymun = 0
				this.curr_address = ''
			}
		},
		address(value) {
			if (this.checkbox) {
				this.curr_address = value
			}
		},
		citymun(value) {
			if (this.checkbox) {
				this.curr_citymun = value
			}
		},
		barangay(value) {
			if (this.checkbox) {
				this.curr_barangay = value
			}
		},
	},
}
</script>
