import jwtdecode from '../helpers/jwtdecode'

const url = process.env.VUE_APP_API_URL + 'register'

export default {
	namespaced: true,

	state: {
		error: null,
		registerOtpError: null,
		errors: [],
		loading: false,
		otpMobileError: null,
		otpTooManyRequestError: null,
		otpError: null,
		otpLoading: false,
	},
	mutations: {
		setError(state, val) {
			state.error = val
		},
		setRegisterOtpError(state, val) {
			state.registerOtpError = val
		},
		setErrors(state, val) {
			state.errors = val
		},
		setLoading(state, val) {
			state.loading = val
		},
		setOtpMobileError(state, val) {
			state.otpMobileError = val
		},
		setOtpTooManyRequestError(state, val) {
			state.otpTooManyRequestError = val
		},
		setOtpError(state, val) {
			state.otpError = val
		},
		setOtpLoading(state, val) {
			state.otpLoading = val
		},
	},

	actions: {
		async doRequest({ commit }, data) {
			commit('setOtpLoading', true)
			commit('setOtpMobileError', null)
			commit('setOtpTooManyRequestError', null)
			commit('setOtpError', null)

			try {
				const response = await fetch(`${url}/otp`, {
					method: 'POST',
					headers: {
						Authorization: 'Basic cGxheWVyVW5BdXRoOkxhc2Nhc2FzR2FtaW5nQDIwMjIhQFBsYXllclVuQXV0aD09LQ==',
					},
					body: data,
				})

				const res = await response.json()

				if (!response.ok) {
					throw { response, res }
				}
			} catch (error) {
				const errorStatus = error.response.status

				if (errorStatus == 409) {
					commit('setOtpMobileError', error.res.messages.error)
				} else if (errorStatus == 429) {
					commit('setOtpTooManyRequestError', error.res.messages.error)
				} else {
					commit('setOtpError', 'Something went wrong, please try again!')
					commit('setPopUpError', 'Something went wrong, please try again!', {
						root: true,
					})
				}
			} finally {
				commit('setOtpLoading', false)
			}
		},

		async doRegister({ commit }, data) {
			commit('setLoading', true)
			commit('setError', null)
			commit('setRegisterOtpError', null)
			commit('setErrors', [])

			try {
				const response = await fetch(process.env.VUE_APP_API_URL + '/v2/register', {
					method: 'POST',
					headers: {
						Authorization: 'Basic cGxheWVyVW5BdXRoOkxhc2Nhc2FzR2FtaW5nQDIwMjIhQFBsYXllclVuQXV0aD09LQ==',
					},
					body: data,
				})

				const res = await response.json()

				if (!response.ok) {
					throw { response, res }
				} else {
					// Reset all main states
					commit('resetStates', '', { root: true })

					const decodedUserData = jwtdecode(res.userData)

					commit('setUserData', decodedUserData, { root: true })
					commit('setUserToken', res.userData, { root: true })
					localStorage.setItem('userToken', res.userData)
					localStorage.setItem('mobileNumber', decodedUserData.mobile_number)
				}
			} catch (error) {
				const errorStatus = error.response.status

				if (errorStatus == 403) {
					commit('setRegisterOtpError', error.res.messages.error)
				} else if (errorStatus == 422) {
					commit('setErrors', error.res.messages)
				} else {
					commit('setError', 'Something went wrong, please try again!')
					commit('setPopUpError', 'Something went wrong, please try again!', {
						root: true,
					})
				}
			} finally {
				commit('setLoading', false)
			}
		},
	},
}
