<template>
	<div class="home pb-4 pb-sm-6 pt-0 pt-sm-6">
		<div class="bs-container">
			<div class="bs-row bs-g-3">
				<div class="bs-col-lg-8">
					<div class="d-flex flex-column bs-gap-3 bs-w-100">
						<Carousel />
						<AvailableGames />
						<FIBAGames />
						<ScheduledGames />
						<Winners />
					</div>
				</div>
				<div class="bs-col-lg-4">
					<div class="d-flex flex-column bs-gap-3">
						<v-card class="livestream elevation-10 bs-position-relative bs-overflow-hidden" style="border: none !important">
							<img src="@/assets/livestream-thumbnail.webp" alt="..." style="display: block; width: 100%; height: auto" />
							<a
								href="https://www.facebook.com/100082248056340/videos/689448509497266"
								target="_blank"
								rel="noopener noreferrer"
								class="livestream-overlay d-flex justify-center align-center">
								<i class="fas fa-play fa-2x"></i>
							</a>
						</v-card>
						<Announcements />
						<v-card class="app elevation-10">
							<div class="pa-4 pb-2 pa-sm-6 pb-sm-2">
								<h3 class="font-weight-bold mb-2">GameX App</h3>
								<div class="grey--text mb-2">
									<!-- Download the app for the smoother and happier betting experience. -->
									{{ $t('home.download.subtitle') }}
								</div>
								<!-- <div class="mb-4 mb-sm-6">
                  {{
                    android_count == "0" ? "..." : `${android_count} Downloads`
                  }}
                </div>
                <v-btn
                  class="light primary--text bs-position-relative ps-4"
                  depressed
                  block
                  @click="downloadAPK"
                >
                  <div
                    class="bs-position-absolute bs-top-0 bs-start-0 bs-bottom-0 d-flex align-center ps-3"
                  >
                    <i class="fas fa-download"></i>
                  </div>
                  {{ $t("home.download.button") }}
                </v-btn> -->
								<a
									href="https://play.google.com/store/apps/details?id=com.gamexsports.gamexapp&hl=en-PH&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
									target="_blank"
									class="d-flex justify-center">
									<img
										alt="Get it on Google Play"
										src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
										style="width: 70%; height: auto" />
								</a>
							</div>
						</v-card>
						<v-card class="get-started elevation-10">
							<div class="pa-4 pa-sm-6">
								<h3 class="font-weight-bold mb-2">
									<!-- Get Started -->
									{{ $t('home.register.title') }}
								</h3>
								<div class="grey--text mb-4 mb-sm-6">
									<!-- Create your GameX account and start winning today. -->
									{{ $t('home.register.subtitle') }}
								</div>
								<v-btn
									class="light secondary--text bs-position-relative ps-4"
									depressed
									@click="setDialogs('register')"
									style="width: 100%; text-transform: initial">
									<div class="bs-position-absolute bs-top-0 bs-start-0 bs-bottom-0 d-flex align-center ps-3">
										<i class="fas fa-user-plus"></i>
									</div>
									<!-- Register -->
									{{ $t('home.register.button') }}
								</v-btn>
							</div>
						</v-card>
						<Testimonials />
					</div>
				</div>
			</div>
		</div>

		<!-- Responsible Gaming -->
		<ResponsibleGamingDialog />

		<Register v-if="registerDialog" @setDialogs="setDialogs" />
		<LoginDialog v-if="loginDialog" @setDialogs="setDialogs" />
	</div>
</template>

<script>
import Carousel from '@/components/Carousel'
import ResponsibleGamingDialog from '@/components/ResponsibleGamingDialog.vue'
import Register from '@/components/Register'
import LoginDialog from '@/components/LoginDialog'
import AvailableGames from '@/components/app/home_page/available_games/AvailableGames.vue'
import FIBAGames from '@/components/app/home_page/fiba_games/FIBAGames.vue'
import ScheduledGames from '@/components/app/home_page/scheduled_games/ScheduledGames.vue'
import Winners from '@/components/app/home_page/winners/Winners.vue'
import Announcements from '@/components/Announcements.vue'
import Testimonials from '@/components/Testimonials.vue'
import '@/assets/home.css'

export default {
	name: 'Home',
	components: {
		Carousel,
		ResponsibleGamingDialog,
		Register,
		LoginDialog,
		AvailableGames,
		FIBAGames,
		ScheduledGames,
		Winners,
		Announcements,
		Testimonials,
	},

	data: () => ({
		registerDialog: false,
		loginDialog: false,
		dialogs: ['login', 'forgot', 'register'],
		referralCode: '',
		// android_count: 0,
		// ios_count: 0,
	}),

	// computed: {
	//   ...mapState("downloads_count", ["counts", "countsLoading"]),
	// },

	mounted() {
		// this.doGetDownloadCounts();

		const $params = this.$route.params
		const urlQuery = this.$route.query

		if ('referral_code' in urlQuery) {
			this.registerDialog = true
		} else {
			if ($params.referral_type != 'vNG9y' && $params.referral_type != 'dNBKW' && $params.referral_type != 'lygzW') {
				this.$router
					.push({
						name: 'Home',
					})
					.catch(() => {})
			} else {
				if ($params.referral_type && $params.sub_type && $params.referral_code) {
					if (urlQuery.overlay != 'register' && !urlQuery.overlay) {
						this.$router.push({
							name: this.$route.name,
							query: { overlay: 'register' },
						})

						this.registerDialog = true
					}
				}
			}
		}
	},

	methods: {
		// ...mapActions("downloads_count", [
		//   "getRecordsCount",
		//   "update_android_count",
		//   "update_ios_count",
		// ]),

		// downloadAPK() {
		//   const fileUrl =
		//     "https://players-api.gamexsports.com.ph/public/apk/gamex.apk";
		//   const link = document.createElement("a");
		//   link.href = fileUrl;
		//   link.click();

		//   this.doUpdateDownloadAndroidCount();
		// },

		// async doGetDownloadCounts() {
		//   await this.getRecordsCount();

		//   this.setDownloadAppsCount();
		// },

		// setDownloadAppsCount() {
		//   if (Object.keys(this.counts).length) {
		//     this.counts.forEach((item) => {
		//       switch (item.id) {
		//         case "1": {
		//           const originalCount = this.moneyFormat(parseInt(item.download));
		//           const countArray = originalCount.split(",");

		//           if (countArray.length > 1) {
		//             const firstValue = countArray[0].charAt(0);
		//             const secondValue = `${
		//               parseInt(countArray[1].charAt(0)) > 0
		//                 ? `.${countArray[1].charAt(0)}`
		//                 : ""
		//             }k`;
		//             this.android_count = `${firstValue}${secondValue}`;
		//           } else {
		//             this.android_count = originalCount;
		//           }
		//           break;
		//         }
		//         case "2": {
		//           // For IOS
		//           break;
		//         }
		//       }
		//     });
		//   }
		// },

		// async doUpdateDownloadAndroidCount() {
		//   await this.update_android_count();
		//   this.setDownloadAppsCount();
		// },

		// async doUpdateDownloadIOSCount() {
		//   this.$store.commit("downloads_count/setCounts", []);
		//   await this.update_ios_count();
		//   this.setDownloadAppsCount();
		// },

		setDialogs(selectedDialog, referral) {
			if (referral) {
				this.referralCode = referral
			} else {
				this.referralCode = ''
			}

			let newVal = !this[`${selectedDialog}Dialog`]

			this[`${selectedDialog}Dialog`] = newVal

			this.dialogs.forEach((dialog) => {
				if (dialog != selectedDialog) {
					this[`${dialog}Dialog`] = false
				}
			})

			// if (newVal) {
			//   if (this.$route.query.overlay != selectedDialog) {
			//     this.$router
			//       .push({
			//         name: this.$route.name,
			//         query: {
			//           overlay: selectedDialog,
			//         },
			//       })
			//       .catch(() => { });
			//   }
			// } else {
			//   this.$router
			//     .push({
			//       name: this.$route.name,
			//       query: null,
			//     })
			//     .catch(() => { });
			// }
		},

		moneyFormat(val) {
			return val.toLocaleString(undefined, {
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			})
		},
	},
}
</script>

<style scoped>
.hero {
	background-image: url('../assets/img/hero-bg.webp');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
.hero h1 {
	font-size: 40px;
}
.hero p {
	font-size: 18px;
	width: 50%;
}
@media (max-width: 576px) {
	.hero h1 {
		font-size: 28px;
	}
	.hero p {
		font-size: 14px;
		width: 100%;
	}
}
</style>
