<template>
	<v-dialog v-model="dialog" max-width="400" scrollable>
		<v-card class="bs-shadow-none" style="overflow-y: auto">
			<div class="pa-4 pa-sm-6" style="overflow-y: overlay !important">
				<h3 class="font-weight-bold mb-2">
					{{ `Withdraw — ${ptopDetails.bank}` }}
				</h3>
				<div v-if="success">
					<v-alert type="success" text class="rounded-lg mt-4 mt-sm-6 mb-0">
						Successfully submitted! Your token will be transferred to your
						{{ ptopDetails.bank }} account after our team approved your request.
					</v-alert>

					<div class="d-flex justify-end mt-4 mt-sm-6">
						<v-btn text class="light" @click="setDialogs('ptop')"> Close </v-btn>
					</div>
				</div>

				<v-stepper v-model="stepper" class="bs-shadow-none">
					<v-stepper-items>
						<v-stepper-content step="1" class="bs-shadow-none pa-0">
							<div v-if="!success && !confirm_details">
								<v-form ref="form" @submit.prevent="showConfirmDialog">
									<div class="grey--text mb-4 mb-sm-6">Enter or select a token amount</div>
									<v-alert type="info" color="blue" role="alert" text class="mb-4 mb-sm-6">
										Cash-out transactions are from 8:00 AM to 10:00 PM only. Transactions beyond this time will be processed the
										following day.
									</v-alert>
									<v-text-field
										v-model="points"
										:rules="[rules.required, minPoints, actualPointsLimit]"
										label="Amount"
										placeholder="Enter amount"
										:hint="`Minimum token amount to withdraw is ${min_points}.`"
										persistent-hint
										outlined
										dense
										:min="min_points"
										:max="parseInt(actualPoints)"
										type="number"
										class="mb-4"></v-text-field>
									<div style="margin-right: -8px">
										<v-btn-toggle v-if="pointsArray.length != 0" class="mb-0" style="width: 100%">
											<div class="bs-row bs-g-2" style="width: 100%">
												<div class="bs-col-4" v-for="(item, i) in pointsArray" :key="i">
													<v-btn class="light" depressed block @click="selectAmount(item)" :rules="[rules.required]">
														{{ item.toLocaleString() }}
													</v-btn>
												</div>
											</div>
										</v-btn-toggle>
									</div>
									<div class="mt-4 mt-sm-6">
										<div v-if="!fund_accounts_loading">
											<v-select
												v-if="fund_accounts && fund_accounts.length != 0"
												v-model="account_number"
												:items="fund_accounts"
												label="Account Number"
												dense
												outlined
												item-text="account_number"
												item-value="account_number"
												hide-details="true"></v-select>
											<div v-else>
												<v-text-field
													v-model="account_number"
													:rules="[rules.required, rules.numeric, rules.validMobileNumber]"
													label="Account Number"
													placeholder="Enter your account number"
													outlined
													prefix="+63"
													maxlength="10"
													dense
													type="tel"
													hide-details="auto"></v-text-field>
												<v-checkbox
													v-model="checkbox_step_1"
													:rules="[(v) => !!v || 'You must agree to continue.']"
													class="mt-4 pt-0"
													hide-details="true">
													<template v-slot:label>
														<div style="margin-top: 1.5px">
															I hereby certify that the above number is registered under my name. I understand that
															should it be found that the said number is not registered in my name, it shall be a ground
															for the suspension or cancellation of my account.
														</div>
													</template>
												</v-checkbox>
											</div>
										</div>
										<div v-else>
											<div class="rounded-lg overflow-hidden">
												<v-skeleton-loader type="image" width="100%" height="40"></v-skeleton-loader>
											</div>
										</div>
									</div>

									<div class="d-flex justify-end bs-gap-2 mt-4 mt-sm-6">
										<v-btn text @click="setDialogs('ptop')">Cancel</v-btn>
										<v-btn depressed color="primary" type="submit" :loading="withdrawLoading" :disabled="disableSubmitButton">
											<span v-if="!points">Proceed</span>
											<span v-else>
												{{ `Proceed — ${points ? points.toLocaleString() : null}` }}
											</span>
										</v-btn>
									</div>
								</v-form>
							</div>
						</v-stepper-content>

						<v-stepper-content step="2" class="bs-shadow-none pa-0">
							<div v-if="!success && confirm_details">
								<div class="grey--text mb-4 mb-sm-6">Please review and confirm</div>
								<div class="rounded-lg bs-border pa-4 mb-4">
									<div class="d-flex flex-column bs-gap-2">
										<div class="d-flex justify-space-between">
											<span class="grey--text">Name</span>
											<span class="text-end">{{ account_name }}</span>
										</div>
										<div class="d-flex justify-space-between">
											<span class="grey--text">Account Number</span>
											<span class="text-end">
												{{ account_number }}
											</span>
										</div>
										<div class="d-flex justify-space-between">
											<span class="grey--text">Amount</span>
											<span class="text-end"> {{ points ? points.toLocaleString() : null }}</span>
										</div>
									</div>
								</div>

								<v-form @submit.prevent="handleSubmit" ref="dialogForm">
									<v-checkbox v-model="checkbox_step_2" :rules="[(v) => !!v]" class="pt-0 mb-4 mb-sm-6" hide-details="auto">
										<template v-slot:label>
											<div style="margin-top: 1px">
												I hereby certify that I have reviewed all the information I have provided and that all are true and
												correct. I understand that after clicking the confirm button that no further revisions or changes can
												be made and that the information I have provided shall be the basis for the processing of my request.
											</div>
										</template>
									</v-checkbox>
									<div class="d-flex justify-end bs-gap-2">
										<v-btn
											:disabled="withdrawLoading"
											text
											@click="
												confirm_details = false
												checkbox_step_2 = false
												stepper = 1
											">
											Back
										</v-btn>
										<v-btn :loading="withdrawLoading" :disabled="!checkbox_step_2" color="primary" type="submit" depressed>
											Confirm
										</v-btn>
									</div>
								</v-form>
							</div>
						</v-stepper-content>
					</v-stepper-items>
				</v-stepper>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import formRules from '@/helpers/formRules'
import responseGet from '@/helpers/api_request_get'

export default {
	props: {
		ptopDetails: {
			type: Object,
			required: true,
		},
	},
	data: () => ({
		rules: formRules,
		pointsArray: [100, 200, 300, 400, 500, 1000, 2000, 3000, 4000, 5000, 10000, 20000, 30000, 40000, 50000],
		points: null,
		min_points: 100,
		account_name: '',
		account_number: '',
		fund_accounts: [],
		fund_accounts_loading: false,
		success: false,
		confirm_details: false,
		checkbox_step_1: false,
		checkbox_step_2: false,
		stepper: 1,
	}),
	mounted() {
		this.filterPointsChoices()
		this.getFundAccount()
	},
	computed: {
		...mapState(['actualPoints', 'userData']),
		...mapState('withdraw', ['withdrawError', 'withdrawLoading']),
		dialog: {
			get: function () {
				return true
			},
			set: function () {
				this.setDialogs('ptop')
			},
		},
		disableSubmitButton() {
			if (
				this.points == null ||
				this.account_name == '' ||
				this.account_number == '' ||
				this.points < this.min_points ||
				(this.fund_accounts && this.fund_accounts.length == 0 && !this.checkbox_step_1)
			)
				return true

			return false
		},
	},
	methods: {
		...mapActions('withdraw', ['doSubmit']),
		setDialogs(selectedDialog) {
			this.$emit('setDialogs', selectedDialog)
		},
		async getFundAccount() {
			this.fund_accounts_loading = true

			const form_data = new FormData()

			const statuses = ['Verified']
			statuses.forEach(function (status) {
				form_data.append('status[]', status)
			})
			form_data.append('bank_id', this.ptopDetails.bank == 'GCash' ? 1 : 2)

			const search_param = new URLSearchParams(form_data)
			const res = await responseGet(search_param, 'bank_accounts')

			switch (res.status) {
				case 200: {
					this.fund_accounts = res.data.data
					this.setDefaultAccountNumber()
				}
			}

			this.fund_accounts_loading = false
		},
		setDefaultAccountNumber() {
			if (this.fund_accounts && this.fund_accounts.length != 0) {
				const default_account_number = this.fund_accounts.find(
					(item) => item.is_verified == 'Verified' && item.is_default == 'Yes' && item.is_active == 'Active'
				)

				if (default_account_number) {
					this.account_number = default_account_number.account_number
					this.account_name = default_account_number.account_name
				}
			} else {
				this.account_name = this.userData.name
				this.account_number = this.userData.mobile_number
			}
		},
		async handleSubmit() {
			if (this.$refs.dialogForm.validate() && !this.withdrawLoading) {
				const formData = new FormData()
				formData.append('bank_id', this.ptopDetails.bank_id)
				formData.append('account_name', this.account_name)
				formData.append('account_number', this.account_number)
				formData.append('amount', this.points)

				await this.doSubmit(formData)

				if (!this.withdrawError) {
					this.success = true
					this.$emit('refresh_table')
				}

				this.confirm_details = false
			}
		},
		minPoints(val) {
			if (val >= this.min_points) {
				return true
			} else {
				return 'Minimum token amount to withdraw is ' + this.min_points + '.'
			}
		},
		actualPointsLimit(val) {
			if (val <= parseInt(this.actualPoints)) {
				return true
			} else {
				return 'Not enough token.'
			}
		},
		selectAmount(val) {
			this.points = val
		},

		showConfirmDialog() {
			if (this.$refs.form.validate() && !this.withdrawLoading) {
				this.confirm_details = true
				this.stepper = 2
			}
		},
		filterPointsChoices() {
			this.pointsArray = this.pointsArray.filter((number) => number <= parseFloat(this.actualPoints))
		},
	},
	watch: {
		points(val) {
			this.points = val ? parseFloat(val) : val
		},
	},
}
</script>
