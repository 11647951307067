<template>
	<header
		id="header"
		class="header dark elevation-10 py-4 py-sm-6"
		:class="{ hide: show_element }"
		style="position: sticky; top: 0; right: 0; left: 0; z-index: 10; transition: top ease 0.3s">
		<div class="bs-container">
			<div class="d-flex bs-justify-content-between align-center" style="width: 100%">
				<router-link :to="{ name: 'Home' }" class="d-flex align-center">
					<img v-if="$vuetify.theme.dark" src="@/assets/GAMEX LOGO FINAL 3-02.png" alt="GameX Sports" class="header-logo" />

					<img v-else src="@/assets/GAMEX LOGO FINAL-02.png" alt="GameX Sports" class="header-logo" />
				</router-link>

				<div class="d-flex align-center bs-gap-2 bs-gap-md-3" v-if="$vuetify.breakpoint.mdAndUp">
					<v-btn class="secondary" large @click="setDialogs('register')" depressed style="height: 56px !important">
						{{ $t('header.button.register') }}
					</v-btn>
					<v-btn class="primary" large @click="setDialogs('login')" depressed style="height: 56px !important">
						{{ $t('header.button.login') }}
					</v-btn>
				</div>
				<div class="d-flex align-center bs-gap-2 bs-gap-md-3" v-if="!$vuetify.breakpoint.mdAndUp">
					<v-btn class="light secondary--text" icon @click="setDialogs('register')" dark>
						<i class="fas fa-user-plus"></i>
					</v-btn>
					<v-btn class="light primary--text" icon @click="setDialogs('login')" dark>
						<i class="fas fa-sign-in-alt"></i>
					</v-btn>
				</div>
			</div>
		</div>

		<v-snackbar v-model="snackbar" top color="error" :timeout="3000">
			<v-icon class="me-3"> mdi-alert-circle-outline </v-icon>
			Your account has been logged into another device.
			<template v-slot:action="{ attrs }">
				<v-btn icon v-bind="attrs" @click="snackbar = false" small>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>

		<LoginDialog v-if="loginDialog" @setDialogs="setDialogs" />
		<ForgotDialog v-if="forgotDialog" @setDialogs="setDialogs" />
		<Register v-if="registerDialog" :referralCode="referralCode" @setDialogs="setDialogs" />
	</header>
</template>

<script>
import LoginDialog from '@/components/LoginDialog'
import ForgotDialog from '@/components/ForgotDialog'
import Register from '@/components/Register'

export default {
	components: { LoginDialog, ForgotDialog, Register },
	data: () => ({
		loginDialog: false,
		forgotDialog: false,
		registerDialog: false,
		dialogs: ['login', 'forgot', 'register'],
		referralCode: '',
		snackbar: false,
		last_scroll_position: 0,
		show_element: true,
		shadow: false,
	}),
	mounted() {
		window.addEventListener('scroll', this.handleScroll)
		// window.addEventListener("scroll", this.applyShadow);

		this.$root.$on('setDialogs', (val) => {
			this.setDialogs(val)
		})

		const urlQuery = this.$route.query

		if (Object.keys(urlQuery).length) {
			let overlay = urlQuery.overlay
			let is_force = urlQuery.is_force

			const referral = urlQuery.referral_code

			if (overlay) {
				this.setDialogs(overlay, referral)
			}

			if (is_force == '1') {
				this.snackbar = true
			}
		}
	},
	destroyed() {
		window.removeEventListener('scroll', this.handleScroll)
		// window.removeEventListener("scroll", this.applyShadow);
	},
	methods: {
		setDialogs(selectedDialog, referral) {
			if (referral) {
				this.referralCode = referral
			} else {
				this.referralCode = ''
			}

			let newVal = !this[`${selectedDialog}Dialog`]

			this[`${selectedDialog}Dialog`] = newVal

			this.dialogs.forEach((dialog) => {
				if (dialog != selectedDialog) {
					this[`${dialog}Dialog`] = false
				}
			})

			if (newVal) {
				if (this.$route.query.overlay != selectedDialog) {
					this.$router
						.push({
							name: this.$route.name,
							query: {
								overlay: selectedDialog,
							},
						})
						.catch(() => {})
				}
			} else {
				this.$router
					.push({
						name: this.$route.name,
						query: null,
					})
					.catch(() => {})
			}
		},
		handleScroll() {
			const currentScrollPosition = window.pageYOffset

			if (currentScrollPosition > this.last_scroll_position) {
				this.show_element = false
			} else {
				this.show_element = true
			}

			this.last_scroll_position = currentScrollPosition
		},
		// applyShadow() {
		//   if (window.scrollY > 0) {
		//     this.shadow = true;
		//   } else {
		//     this.shadow = false;
		//   }
		// },
	},
}
</script>

<style scoped>
#header.hide {
	top: -104px !important;
}

@media (max-width: 768px) {
	#header.hide {
		top: -72px !important;
	}
}
</style>
