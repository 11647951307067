<template>
	<v-dialog v-model="responsible_gaming_dialog" scrollable max-width="600">
		<v-card class="bs-shadow-none" style="height: 95vh !important">
			<v-card-text class="responsible-gaming pa-6 pa-md-8" style="position: relative">
				<ResponsibleGaming />
				<div class="d-flex justify-center justify-sm-end mt-12">
					<v-btn color="primary" depressed large @click="close_responsible_gaming_dialog()"> I agree and confirm </v-btn>
				</div>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import ResponsibleGaming from '@/components/ResponsibleGaming.vue'

export default {
	data: () => ({
		responsible_gaming_dialog: true,
	}),
	mounted() {
		this.set_gaming_responsible_gaming_dialog()
	},

	components: {
		ResponsibleGaming,
	},
	methods: {
		set_gaming_responsible_gaming_dialog() {
			const rgd = sessionStorage.getItem('_rgd')

			if (rgd == 'true') {
				this.responsible_gaming_dialog = false
			}
		},
		close_responsible_gaming_dialog() {
			sessionStorage.setItem('_rgd', 'true')
			this.responsible_gaming_dialog = false
		},
	},
}
</script>

<style scoped>
.v-card .responsible-gaming {
	color: #fff !important;
	background: rgb(0, 115, 211) !important;
	background: radial-gradient(circle, rgba(0, 115, 211, 1) 0%, rgba(1, 105, 190, 1) 100%) !important;
}
</style>
