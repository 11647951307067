<template>
	<div>
		<v-card>
			<v-card-text>
				<div class="d-flex flex-column justify-center">
					<span class="text-h6 align-self-center mb-10">Please complete info to continue. </span>

					<v-btn class="primary text-capitalize align-self-center" @click="showDialog">Complete Registration</v-btn>
				</div>
			</v-card-text>
		</v-card>

		<vee-form v-slot="{ handleSubmit, invalid, reset }">
			<v-dialog v-model="dialog" persistent width="800" height="800" scrollable>
				<v-card>
					<v-card-title>Complete Registration</v-card-title>

					<v-divider />

					<v-card-text class="pa-3" :class="isMobile ? 'fullscreen-card-text' : ''">
						<v-row no-gutters>
							<!-- First name -->
							<v-col cols="12">
								<vee-input
									rules="required|min:3"
									v-slot="{ errors, passed }"
									name="First name"
									:custom-messages="{ required: 'First Name field is required', min: 'Minimum 3 characters are required' }">
									<v-text-field
										:success="passed"
										disabled
										label="First Name"
										outlined
										:hide-details="!errors.length"
										v-model="playerInfo.first_name"
										clearable
										dense
										:error-messages="errors.join(',')" />
								</vee-input>
							</v-col>

							<!-- Middle name -->
							<v-col lg="6" sm="6" cols="12">
								<v-text-field label="Middle Name" disabled outlined hide-details v-model="playerInfo.middle_name" dense />
							</v-col>

							<!-- Last name -->
							<v-col lg="6" sm="6" cols="12">
								<vee-input
									rules="required|min:3"
									v-slot="{ errors, passed }"
									name="Last Name"
									:custom-messages="{ required: 'Last Name field is required', min: 'Minimum 3 characters are required' }">
									<v-text-field
										label="Last Name"
										disabled
										outlined
										:success="passed"
										:hide-details="!errors.length"
										v-model="playerInfo.last_name"
										clearable
										dense
										:error-messages="errors.join(',')" />
								</vee-input>
							</v-col>

							<!-- Birth date -->
							<v-col lg="6" sm="6" cols="12">
								<vee-input
									rules="required|minimumAge"
									v-slot="{ errors, passed }"
									name="Birth Date"
									:custom-messages="{ required: 'Birth Date field is required' }">
									<v-menu offset-y :close-on-content-click="false">
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												disabled
												:success="passed"
												v-model="playerInfo.birthdate"
												label="Birth Date"
												readonly
												outlined
												:error-messages="errors.join(',')"
												:hide-details="!errors.length"
												clearable
												dense
												v-bind="attrs"
												v-on="on" />
										</template>

										<v-date-picker
											:max="currentDate"
											placeholder="Birthdate"
											:error-messages="errors.join(',')"
											:hide-details="!errors.length"
											v-model="playerInfo.birthdate"
											color="primary"
											header-color="primary"
											class="elevation-2" />
									</v-menu>
								</vee-input>
							</v-col>

							<!-- Email Address -->
							<v-col lg="6" sm="6" cols="12">
								<vee-input rules="email" v-slot="{ errors }" name="email_address">
									<v-text-field
										label="Email Address (Optional)"
										outlined
										v-model="playerInfo.email_address"
										@input="emailAddressErrors = null"
										clearable
										dense
										:error-messages="errors.join(',') || emailAddressErrors" />
								</vee-input>
							</v-col>

							<!-- Gender -->
							<v-col lg="6" sm="6" cols="12">
								<vee-input
									rules="required"
									v-slot="{ errors, passed }"
									name="Gender"
									:custom-messages="{ required: 'Gender field is required' }">
									<v-select
										:success="passed"
										v-model="playerInfo.gender"
										label="Gender"
										:items="['Male', 'Female']"
										outlined
										:error-messages="errors.join(',')"
										dense />
								</vee-input>
							</v-col>

							<!-- Marital Status -->
							<v-col lg="6" sm="6" cols="12">
								<vee-input
									rules="required"
									v-slot="{ errors, passed }"
									name="Marital Status"
									:custom-messages="{ required: 'Marital Status field is required' }">
									<v-select
										outlined
										:success="passed"
										dense
										:error-messages="errors.join(',')"
										label="Marital Status"
										v-model="playerInfo.marital_status"
										:items="['Single', 'Married', 'Widowed', 'Divorced']" />
								</vee-input>
							</v-col>

							<!-- Source of income -->
							<v-col :lg="[1, 2].includes(sourceOfIncome?.id) ? 6 : 12" cols="12">
								<vee-input
									rules="required"
									v-slot="{ errors, passed }"
									:custom-messages="{ required: 'Source of income field is required' }">
									<v-select
										v-model="sourceOfIncome"
										label="Source of income"
										:items="sourceOfIncomeItems"
										item-text="source"
										outlined
										:success="passed"
										:error-messages="errors.join(',')"
										dense
										return-object
										item-value="id" />
								</vee-input>
							</v-col>

							<!-- Nature of work -->
							<v-col cols="12" lg="6" v-if="sourceOfIncome?.id === 1">
								<vee-input
									rules="required|emptyObject"
									v-slot="{ errors, passed }"
									name="Nature of work"
									:custom-messages="{ required: 'Nature of work field is required' }">
									<v-select
										:success="passed"
										v-model="natureOfWork"
										label="Nature of work"
										dense
										:items="natureOfWorkItems"
										item-text="nature"
										outlined
										return-object
										:error-messages="errors.join(',')"
										item-value="id" />
								</vee-input>
							</v-col>

							<!-- Nature of business -->
							<v-col cols="12" lg="6" v-if="sourceOfIncome?.id === 2">
								<vee-input
									rules="required|emptyObject"
									v-slot="{ errors, passed }"
									name="Nature of business"
									:custom-messages="{ required: 'Nature of business field is required' }">
									<v-select
										dense
										:success="passed"
										v-model="natureOfBusiness"
										label="Nature of Business"
										:items="natureOfBusinessItems"
										item-text="nature"
										outlined
										return-object
										:error-messages="errors.join(',')"
										item-value="id" />
								</vee-input>
							</v-col>

							<!-- Nationalities -->
							<v-col sm="6" cols="12">
								<vee-input
									rules="required"
									v-slot="{ errors, passed }"
									name="Nationality"
									:custom-messages="{ required: 'Nationality field is required' }">
									<v-select
										v-model="nationality"
										:success="passed"
										label="Nationality"
										:items="nationalitiesItems"
										item-text="nationality"
										outlined
										:error-messages="errors.join(',')"
										dense
										return-object
										item-value="id" />
								</vee-input>
							</v-col>

							<!-- Birth Place -->
							<v-col sm="6" lg="6" cols="12">
								<vee-input
									rules="required|min:3"
									v-slot="{ errors, passed }"
									name="birth place"
									:custom-messages="{ required: 'Birth Place field is required', min: 'Minimum 3 characters is required' }">
									<v-text-field
										label="Birth Place"
										:success="passed"
										outlined
										v-model="playerInfo.birthplace"
										clearable
										dense
										:error-messages="errors.join(',')" />
								</vee-input>
							</v-col>

							<!-- Address -->
							<v-col cols="12">
								<vee-input
									rules="required|min:5"
									v-slot="{ errors, passed }"
									name="address"
									:custom-messages="{ required: 'Address field is required', min: 'Minimum 5 characters is required' }">
									<v-text-field
										label="Address"
										outlined
										:success="passed"
										v-model="playerInfo.address"
										clearable
										dense
										:error-messages="errors.join(',')" />
								</vee-input>
							</v-col>

							<!-- Municipalities -->
							<v-col cols="12" :lg="6">
								<vee-input
									rules="required"
									v-slot="{ errors, passed }"
									name="Address"
									:custom-messages="{ required: 'City/Municipality field is required' }">
									<v-combobox
										v-model="municipality"
										:success="passed"
										label="City/Municipality"
										dense
										:items="municipalityItems"
										item-text="citymun"
										return-object
										outlined
										:error-messages="errors.join(',')"
										item-value="id" />
								</vee-input>
							</v-col>

							<!-- Barangays -->
							<v-col cols="12" :lg="6">
								<vee-input
									rules="required"
									v-slot="{ errors, passed }"
									name="Address"
									:custom-messages="{ required: 'Barangay field is required' }">
									<v-combobox
										:disabled="!municipality"
										v-model="barangay"
										label="Barangay"
										:success="passed"
										dense
										:items="barangayItems"
										item-text="barangay"
										return-object
										outlined
										:error-messages="errors.join(',')"
										item-value="id" />
								</vee-input>
							</v-col>
						</v-row>
					</v-card-text>

					<v-divider />

					<v-card-actions>
						<v-spacer />

						<v-btn
							text
							@click="
								dialog = false
								reset()
							"
							>Cancel</v-btn
						>

						<v-btn class="primary" type="button" @click="handleSubmit(saveInfo(invalid))" small>Save</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</vee-form>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import nationalitiesItems from '@/data/nationalities.json'
import sourceOfIncomeItems from '@/data/sourceOfIncome.json'
import natureOfWorkItems from '@/data/natureOfWork.json'
import natureOfBusinessItems from '@/data/natureOfBusiness.json'
import municipalityItems from '@/data/citymuns.json'
import barangayItems from '@/data/barangays.json'
import axios from 'axios'
import { useWindowSize } from '@vueuse/core'

export default {
	name: 'CompleteRegister',
	data: () => ({
		dialog: false,
		playerInfo: {
			first_name: '',
			middle_name: '',
			last_name: '',
			gender: '',
			birthdate: '',
			birthplace: '',
			marital_status: '',
			address: '',
			email_address: '',
		},
		nationality: null,
		nationalitiesItems,
		sourceOfIncome: null,
		sourceOfIncomeItems,
		natureOfWork: null,
		natureOfWorkItems,
		natureOfBusiness: null,
		natureOfBusinessItems,
		municipality: null,
		municipalityItems,
		barangay: null,
		barangayItems,
		emailAddressErrors: null,
	}),
	computed: {
		...mapState(['appData']),
		currentDate() {
			return new Date().toISOString()
		},
		filteredBarangay() {
			return this.municipality ? this.barangayItems.filter((barangay) => barangay.citymun_id === Number(this.municipality)) : []
		},
		isMobile() {
			return useWindowSize().width.value <= 700
		},
	},
	methods: {
		showDialog() {
			this.dialog = true

			Object.keys(this.playerInfo).map((key) => (this.playerInfo[key] = this.appData.account_details[key]))

			this.sourceOfIncome = this.sourceOfIncomeItems.filter((soc) => soc.id === Number(this.appData.account_details.source_of_income))[0]

			this.natureOfWork = this.natureOfWorkItems.filter((now) => now.id === Number(this.appData.account_details.nature_of_work))[0]

			this.natureOfBusiness = this.natureOfBusinessItems.filter((now) => now.id === Number(this.appData.account_details.nature_of_business))[0]

			this.nationality = this.nationalitiesItems.filter((nat) => nat.id === Number(this.appData.account_details.nationality))[0]
		},
		async saveInfo(invalid) {
			if (invalid) return

			try {
				const data = {
					...this.playerInfo,
					nationality: this.nationality?.id,
					source_of_income: this.sourceOfIncome?.id,
					...(this.sourceOfIncome?.id === 1 && { nature_of_work: this.natureOfWork?.id }),
					...(this.sourceOfIncome?.id === 2 && { nature_of_business: this.natureOfBusiness?.id }),
					barangay_id: this.barangay?.id,
				}

				const formData = new FormData()

				Object.keys(data).map((key) => formData.append(key, data[key]))

				await axios.post(process.env.VUE_APP_API_URL + '/v2/account/update_personal_info', formData, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('userToken')}`,
					},
				})

				this.appData.registration_complete = true
			} catch (error) {
				this.emailAddressErrors = error.response.data?.messages?.email_address
			}
		},
	},
	mounted() {},
	watch: {
		municipality(municipality) {
			this.barangayItems = this.barangayItems.filter((barangay) => barangay.citymun_id === Number(municipality.id))
		},
		appData(value) {
			const currentBarangay = JSON.parse(value.account_details.barangay)

			const [barangay] = this.barangayItems.filter((brgy) => brgy.id === currentBarangay.id)

			const [municipality] = this.municipalityItems.filter((mnsp) => mnsp.id === barangay.citymun_id)

			this.municipality = municipality

			this.barangay = barangay
		},
	},
}
</script>

<style scoped>
.fullscreen-card-text {
	height: calc(100vh - 63.98px - 63.98px);
	overflow: auto;
}
</style>
