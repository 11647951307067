import Vue from 'vue'
import App from './AppNew.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vidle from 'v-idle'
import VueI18n from 'vue-i18n'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email, min } from 'vee-validate/dist/rules'

Vue.use(VueI18n)
Vue.use(Vidle)

// Create VueI18n instance with options
const i18n = new VueI18n({
	locale: 'en', // set locale
	messages: {
		en: require('../src/locales/en.json'),
		ph: require('../src/locales/ph.json'),
	}, // set locale messages
})

// NProgress
import '../node_modules/nprogress/nprogress.css'

// Global Styles
import './assets/bootstrap.css'
import './assets/main.css'

Vue.config.productionTip = false
Vue.component('vee-input', ValidationProvider)
Vue.component('vee-form', ValidationObserver)

extend('required', {
	...required,
	getMessage: (field) => `${field} is required`,
})

extend('email', {
	...email,
	message: 'Please provide a valid email',
}) 

extend('min', {
	...min, 
}) 

extend('emptyObject', {
	validate(value) {
		return {
			required: true,
			valid: ['', null, undefined, {}].indexOf(value) === -1,
		}
	},
	computesRequired: true,
})

extend('minimumAge', {
	validate(value) {
		// Parse the date value
		const parsedDate = new Date(value)

		// Calculate the difference in years
		let age = new Date().getFullYear() - parsedDate.getFullYear()
		const ageMonths = new Date().getMonth() - parsedDate.getMonth()
		if (ageMonths < 0 || (ageMonths === 0 && new Date().getDate() < parsedDate.getDate())) {
			age--
		}

		// Check if the age is greater than or equal to 21
		const isValid = age >= 21

		return {
			valid: isValid,
			message: `Your age must 21 years old or above.`,
		}
	},
	message: 'Your age must 21 years old or above.',
})

new Vue({
	router,
	store,
	vuetify,
	i18n,
	render: (h) => h(App),
}).$mount('#app')
