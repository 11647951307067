<template>
	<v-dialog v-model="dialog" scrollable persistent width="500">
		<v-form ref="form" :disabled="submit_loading" @submit.prevent="handleSubmit">
			<v-card class="register bs-shadow-none">
				<v-card-title class="d-flex justify-space-between">
					<img
						v-if="$vuetify.theme.dark"
						src="@/assets/GAMEX LOGO FINAL 3-02.png"
						style="width: auto"
						:style="$vuetify.breakpoint.mdAndUp ? 'height: 50px' : 'height: 40px'"
						alt="GameX Sports" />
					<img
						v-else
						src="@/assets/GAMEX LOGO FINAL-02.png"
						style="width: auto"
						:style="$vuetify.breakpoint.mdAndUp ? 'height: 50px' : 'height: 40px'"
						alt="GameX Sports" />

					<v-btn icon @click="setDialogs('register')" small class="float-end">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>

				<v-divider />

				<v-card-text class="pa-3">
					<v-alert color="blue" text role="alert"> GameX Sports requires registered GCash or Paymaya mobile number. </v-alert>
					<!-- OTP & Mobile Number -->
					<v-row no-gutters v-if="!otpSuccess">
						<!-- Mobile Number -->
						<v-col cols="12">
							<div style="position: relative">
								<v-text-field
									ref="mobile_number"
									v-model="mobile_number"
									persistent-hint
									prefix="+63"
									label="Mobile Number"
									outlined
									dense
									maxlength="10"
									hide-details="auto"
									:error-messages="validation_error_message.mobile_number"
									:rules="[rules.required, rules.validMobileNumber, mobileNumberExist, tooManyAttempts]" />

								<div class="d-flex align-center pe-3" style="margin-top: 9px; position: absolute; top: 0; right: 0; z-index: 2">
									<a
										href="#"
										@click.prevent="handleClick"
										:style="
											!hasValidMobileNumber || otpLoading || !requestOtp ? 'pointer-events: none; opacity: 0.5;' : undefined
										">
										<v-progress-circular
											v-if="otpLoading"
											indeterminate
											:size="14"
											:width="2"
											color="primary"></v-progress-circular>
										<span v-else>Send OTP</span>
										<span v-if="countDown && !requestOtp">{{ ` (${countDown})` }}</span>
									</a>
								</div>
							</div>
						</v-col>

						<!-- OTP Number -->
						<v-col cols="12">
							<v-text-field
								ref="otp"
								v-model="otp"
								:error-messages="validation_error_message.otp"
								:rules="[rules.required, rules.minimum(6), rules.numeric, otpHasError]"
								label="OTP"
								placeholder="XXXXXX"
								outlined
								dense
								maxlength="6"
								:disabled="enableOTP || otpSuccess"
								hide-details="auto">
							</v-text-field>
						</v-col>

						<v-col cols="12">
							<v-btn
								:loading="isFetchingOTP"
								color="primary"
								type="button"
								depressed
								block
								class="mb-3"
								@click="verifyOTP"
								:disabled="otp.length < 6 || !hasValidMobileNumber">
								Next
								<v-icon class="ms-2">mdi-arrow-right-thin</v-icon>
							</v-btn>
						</v-col>
					</v-row>

					<!-- Field when OTP is Validated -->
					<v-row no-gutters v-if="otpSuccess">
						<!-- First Name -->
						<v-col cols="6">
							<v-text-field
								ref="firstname"
								v-model="firstname"
								:rules="[rules.required, rules.alphaSpace, rules.minimum(2), rules.maximum(30)]"
								label="First Name"
								outlined
								autofocus
								dense
								hide-details="auto" />
						</v-col>

						<!-- Middle Name -->
						<v-col cols="6">
							<v-text-field
								v-model="middlename"
								:rules="[rules.required, rules.alphaSpace, rules.minimum(2), rules.maximum(30)]"
								label="Middle Name"
								outlined
								hide-details="auto"
								dense />
						</v-col>

						<!-- Last Name -->
						<v-col cols="6">
							<v-text-field
								v-model="lastname"
								:rules="[rules.required, rules.alphaSpace, rules.minimum(2), rules.maximum(30)]"
								label="Last Name"
								outlined
								dense
								hide-details="auto" />
						</v-col>

						<!-- Source of Income -->
						<v-col cols="6">
							<v-autocomplete
								v-model="source_of_income"
								:items="sourceOfIncome"
								:rules="[rules.required]"
								label="Source of Income"
								outlined
								dense
								item-text="source"
								item-value="id"
								hide-details="auto" />
						</v-col>

						<!-- Nature of Work -->
						<v-slide-y-transition>
							<v-col cols="12" v-if="source_of_income == 1">
								<v-autocomplete
									v-model="nature_of_work"
									:items="natureOfWork"
									:rules="[rules.required, validateNatureOfWork]"
									label="Nature of Work"
									outlined
									dense
									item-text="nature"
									item-value="id"
									hide-details="auto" />
							</v-col>
						</v-slide-y-transition>

						<!-- Source of Income -->
						<v-slide-y-transition>
							<v-col cols="12" v-if="source_of_income == 2">
								<v-autocomplete
									v-model="nature_of_business"
									:items="natureOfBusiness"
									:rules="[rules.required]"
									label="Nature of Business"
									outlined
									dense
									item-text="nature"
									item-value="id"
									hide-details="auto"></v-autocomplete>
							</v-col>
						</v-slide-y-transition>

						<!-- Birth Date -->
						<v-col cols="12">
							<v-menu ref="birthdate_menu" v-model="birthdate_menu" :close-on-content-click="false" offset-y min-width="auto" left>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="birthdate"
										:rules="[rules.required, isUnder21]"
										label="Date of Birth"
										outlined
										dense
										readonly
										v-bind="attrs"
										v-on="on"
										hide-details="auto"
										@keyup="birthdate_menu = true"
										@keydown="birthdate_menu = false"></v-text-field>
								</template>
								<v-date-picker
									:max="birthdate_max"
									v-model="birthdate"
									:active-picker.sync="birthdateActivePicker"
									no-title
									@change="$refs.birthdate_menu.save(birthdate)"></v-date-picker>
							</v-menu>
						</v-col>

						<!-- Email Address -->
						<v-col cols="12">
							<v-text-field
								ref="email_address"
								v-model="email_address"
								:rules="[rules.validEmail, emailAddressExist]"
								label="Email Address (Optional)"
								outlined
								dense
								type="email"
								hide-details="auto" />
						</v-col>

						<!-- Address -->
						<!--<v-col cols="12">
							<v-text-field
								v-model="address"
								:rules="[rules.required, rules.minimum(5), rules.maximum(200)]"
								label="Street, House/Lot & Block #, Room/Floor/Unit #"
								outlined
								hide-details="auto"
								dense />
						</v-col>-->

						<!-- City/Municipality -->
						<!--<v-col cols="6">
							<v-autocomplete
								v-model="citymun"
								:items="citymuns"
								:rules="[rules.required]"
								label="City/Municipality"
								outlined
								dense
								item-text="citymun"
								return-object
								hide-details="auto" />
						</v-col>-->

						<!-- Barangay -->
						<!--<v-col cols="6">
							<v-autocomplete
								v-model="barangay"
								:items="filteredBarangays"
								:rules="[rules.required]"
								label="Barangay"
								outlined
								dense
								item-text="barangay"
								return-object
								hide-details="auto" />
						</v-col>-->

						<!-- Valid ID -->
						<v-col cols="6">
							<div style="position: relative">
								<div
									class="light d-flex align-center justify-center text-center rounded-lg overflow-hidden ma-0"
									style="width: 100%; height: 150px; position: relative; border-width: 1px !important">
									<div v-if="valid_id_url" style="width: 100%">
										<img :src="valid_id_url" alt="..." style="width: 100%; height: auto" class="d-block" />
										<div
											class="d-flex justify-end white--text pa-3"
											style="position: absolute; top: 0; right: 0; left: 0; z-index: 2">
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<a
														href="#"
														@click.prevent="
															valid_id = null
															valid_id_url = null
														"
														class="white--text"
														v-bind="attrs"
														v-on="on">
														<v-icon small dark> mdi-close </v-icon>
													</a>
												</template>
												<span>Clear</span>
											</v-tooltip>
										</div>
									</div>

									<div v-else class="ma-4 ma-sm-6" style="width: 100%">
										<label for="reg_valid_id" class="d-inline" style="cursor: pointer">
											<span class="primary--text">Attach</span>
											Valid ID
										</label>
									</div>
								</div>
							</div>

							<v-file-input
								id="reg_valid_id"
								ref="valid_id"
								v-model="valid_id"
								@change="validIDOnFileChange"
								@click:clear="validIDClearFile"
								accept=".png,.jpg"
								prepend-icon=""
								append-icon="mdi-image"
								show-size
								label="Valid ID"
								placeholder="Attach"
								dense
								outlined
								hide-details="auto"
								class="d-none"></v-file-input>
						</v-col>

						<!-- Attach Selfie -->
						<v-col cols="6">
							<div style="position: relative">
								<div
									class="light d-flex align-center justify-center text-center rounded-lg overflow-hidden ma-0"
									style="width: 100%; height: 150px; position: relative; border-width: 1px !important">
									<div v-if="selfie_url" style="width: 100%">
										<img :src="selfie_url" alt="..." style="width: 100%; height: auto" class="d-block" />
										<div
											class="d-flex justify-end white--text pa-3"
											style="position: absolute; top: 0; right: 0; left: 0; z-index: 2">
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<a
														href="#"
														@click.prevent="
															selfie = null
															selfie_url = null
														"
														class="white--text"
														v-bind="attrs"
														v-on="on">
														<v-icon small dark> mdi-close </v-icon>
													</a>
												</template>
												<span>Clear</span>
											</v-tooltip>
										</div>
									</div>
									<div v-else class="ma-4 ma-sm-6" style="width: 100%">
										<label for="reg_selfie" class="d-inline" style="cursor: pointer">
											<span class="primary--text">Attach</span>
											Selfie with the ID Submitted
										</label>
									</div>
								</div>
							</div>

							<v-file-input
								id="reg_selfie"
								ref="selfie"
								v-model="selfie"
								@change="selfieOnFileChange"
								@click:clear="selfieClearFile"
								accept=".png,.jpg"
								prepend-icon=""
								append-icon="mdi-image"
								show-size
								label="Selfie"
								placeholder="Attach"
								dense
								outlined
								hide-details="auto"
								class="d-none"></v-file-input>
						</v-col>

						<!-- Password -->
						<v-col cols="12">
							<v-text-field
								ref="password"
								v-model="password"
								:rules="[rules.required, rules.minimum(6)]"
								:append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
								:type="show2 ? 'text' : 'password'"
								label="Password"
								outlined
								dense
								hide-details="auto"
								@click:append="show2 = !show2" />
						</v-col>

						<!-- Agreement -->
						<v-col cols="12">
							<v-card>
								<v-card-text class="pa-3">
									<v-checkbox
										v-model="checkbox"
										:rules="[(v) => !!v || 'You must agree to continue.']"
										class="mt-0 pt-0"
										hide-details="true">
										<template v-slot:label>
											<div style="margin-top: 1.5px; font-size: 11px; text-align: justify">
												I am at least 21 years old of age and not a Government official or employee connected directly with
												the operation of the Government or any of its agencies, member of the Armed Forces of the Philippines,
												including the Army, Navy, Air Force or the Philippine National Police. I also read and understand the
												<a target="_blank" href="/terms_of_use" @click.stop> Terms of Use </a>
												and
												<a target="_blank" href="/privacy_policy" @click.stop> Policy Privacy </a>
												of GameX Sports. Violation of any of the provisions of the
												<a target="_blank" href="/terms_of_use" @click.stop> Terms of Use </a>
												shall be a ground for the cancellation or suspension of his/her account.
											</div>
										</template>
									</v-checkbox>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>
				</v-card-text>

				<v-divider />

				<v-card-actions class="d-flex flex-column">
					<v-btn
						color="primary"
						type="submit"
						depressed
						:disabled="disabledRegister"
						:loading="submit_loading"
						block
						class="mb-3"
						v-if="otpSuccess">
						Signup
						<v-icon class="ms-2">mdi-arrow-right-thin</v-icon>
					</v-btn>

					<p class="text-center">
						Already have an account?

						<br />

						<a href="#" text @click.prevent="setDialogs('login')" class="primary--text"> Login </a>
					</p>
				</v-card-actions>
			</v-card>
		</v-form>

		<v-dialog v-model="successDialog" width="300" persistent>
			<v-card>
				<div class="text-center pa-6">
					<v-icon large class="green--text mb-3"> mdi-check-circle-outline </v-icon>
					<h3 class="font-weight-bold mb-4 mb-sm-6">Success</h3>
					<v-btn depressed v-if="pagcorMode" color="primary" @click="continueVerify">Verify My Account</v-btn>
					<!--<v-btn v-else depressed color="primary" @click="continueGames">Go to my account</v-btn>-->
					<div class="d-flex flex-column justify-space-between">
						<v-btn depressed color="primary" @click="continueGames">Play now</v-btn>
						<div class="h6 grey--text my-1">OR</div>
						<v-btn depressed color="primary" @click="continueVerify">Verify My Account</v-btn>
					</div>
				</div>
			</v-card>
		</v-dialog>

		<AppbarSnack v-if="snackbar" :snackbarData="snackbarData" @setSnackbar="setSnackbar" />
	</v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isObjectEmpty } from '@/helpers/form'
import { format, subYears } from 'date-fns'
import nationalities from '@/data/nationalities.json'
import sourceOfIncome from '@/data/sourceOfIncome.json'
import natureOfWork from '@/data/natureOfWork.json'
import natureOfBusiness from '@/data/natureOfBusiness.json'
import citymuns from '@/data/citymuns.json'
import barangays from '@/data/barangays.json'
import formRules from '@/helpers/formRules'
import AppbarSnack from '@/components/AppbarSnack'
import responseGet from '@/helpers/api_request_get'
// import Camera from "@/components/Camera.vue";
import Compressor from 'compressorjs'

export default {
	name: 'Register',
	components: { AppbarSnack },
	props: {
		referralCode: String,
	},
	data: () => ({
		today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
		rules: formRules,
		firstname: '',
		middlename: '',
		lastname: '',
		gender: ['Male', 'Female'],
		selected_gender: '',
		marital_status: ['Single', 'Married', 'Widowed', 'Divorced'],
		selected_marital_status: '',
		birthdateActivePicker: null,
		birthdate: null,
		birthdate_menu: false,
		birthdate_max: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
		nationality: 175,
		nationalities: nationalities,
		source_of_income: null,
		sourceOfIncome: sourceOfIncome,
		nature_of_work: null,
		natureOfWork: natureOfWork,
		nature_of_business: null,
		natureOfBusiness: natureOfBusiness,
		occupation: '',
		address: '',
		email_address: '',
		emailAddressExistText: '',
		mobile_number: '',
		mobileNumberExistText: '',
		tooManyAttemptsText: '',
		otp: '',
		otp_status: false,
		otp_text: '',
		otp_loading: false,
		otp_error_message: '',
		validation_error_message: [],

		otpErrorText: '',
		otpErrorDesc: '',
		submit_loading: false,
		countDown: 0,
		requestOtp: true,
		show2: false,
		show3: false,
		password: '',
		confirm_password: '',
		checkbox: false,
		successDialog: false,
		snackbar: false,
		snackbarData: {
			snackbarColor: 'success',
			snackbarMsg: 'The OTP has been sent to your phone.',
		},
		referral_type: '',
		sub_type: '',
		referral_code: '',
		stepper: 1,
		valid_id: null,
		valid_id_url: null,
		selfie: null,
		selfie_url: null,
		citymuns: citymuns,
		citymun: '',
		allBarangays: barangays,
		barangay: '',
		otpSuccess: false,
		isFetchingOTP: false,
	}),
	computed: {
		...mapState(['pagcorMode']),
		...mapState('register', [
			'error',
			'registerOtpError',
			'errors',
			'loading',
			'otpMobileError',
			'otpTooManyRequestError',
			'otpError',
			'otpLoading',
		]),
		dialog: {
			get: function () {
				return true
			},
			set: function () {
				this.setDialogs('register')
			},
		},
		filteredBarangays() {
			if (this.citymun && this.citymun.id) {
				return this.allBarangays.filter((bar) => {
					return parseInt(bar.citymun_id) == parseInt(this.citymun.id)
				})
			} else {
				return []
			}
		},
		hasValidMobileNumber() {
			return this.mobile_number &&
				this.rules.validMobileNumber(this.mobile_number) == true &&
				this.mobileNumberExist(this.mobile_number) == true &&
				this.tooManyAttempts(this.mobile_number) == true
				? true
				: false
		},
		enableOTP() {
			if (this.mobile_number && this.mobile_number != '' && this.mobile_number.length == 10) return false

			return true
		},
		enableStepTwo() {
			if (this.mobile_number && this.mobile_number != '' && this.mobile_number.length == 10 && this.otp != '' && this.otp.length == 6)
				return false

			return true
		},
		enableStepThree() {
			if (
				this.firstname == '' ||
				this.middlename == '' ||
				this.lastname == '' ||
				this.isUnder21() != true ||
				this.selected_gender == '' ||
				this.selected_marital_status == '' ||
				this.birthdate === null ||
				this.nationality === null ||
				this.source_of_income == null ||
				this.nature_of_work == '1' ||
				(this.source_of_income == 1 && this.nature_of_work == '0') ||
				(this.source_of_income == 2 && this.nature_of_business == '0') ||
				(this.emailAddressExistText != '' && this.emailAddressExistText == this.email_address) ||
				this.address == '' ||
				this.address.length <= 4 ||
				this.valid_id === null ||
				this.selfie === null
			)
				return true

			return false
		},
		disabledRegister() {
			//if (this.password == '' || this.password.length < 6 || !this.checkbox) return true

			//return false

			return false
		},
		validateNatureOfWork() {
			if (this.nature_of_work == 1) {
				return 'Government officials or employees are not allowed.'
			} else {
				return true
			}
		},
		formCompleted() {
			return true
		},
	},
	mounted() {
		const params = this.$route.params
		const urlQuery = this.$route.query

		if ('referral_code' in urlQuery) {
			this.referral_code = urlQuery.referral_code
		} else {
			if (params.referral_type && params.sub_type && params.referral_code) {
				this.referral_type = params.referral_type
				this.sub_type = params.sub_type
				this.referral_code = params.referral_code
			} else {
				this.referral_code = this.referralCode
			}
		}
	},
	methods: {
		...mapActions('register', ['doRegister', 'doRequest']),
		setDialogs(selectedDialog) {
			this.$emit('setDialogs', selectedDialog)
		},
		maxBirthdate() {
			return format(subYears(new Date(this.today), 21), 'yyyy-MM-dd')
		},
		minBirthdate() {
			return format(subYears(new Date(this.today), 100), 'yyyy-MM-dd')
		},
		emailAddressExist(val) {
			if (val && val == this.emailAddressExistText) {
				return `"${val}" already exist`
			} else {
				return true
			}
		},
		mobileNumberExist(val) {
			if (val == this.mobileNumberExistText) {
				return `+63${val} is already registered.`
			} else {
				return true
			}
		},
		tooManyAttempts(val) {
			if (val == this.tooManyAttemptsText) {
				return 'Too many attempts, please try again after 30 minutes.'
			} else {
				return true
			}
		},
		otpHasError(val) {
			if (val == this.otpErrorText) {
				return this.otpErrorDesc
			} else {
				return true
			}
		},
		countDownTimer(timer) {
			if (this.countDown > 0) {
				setTimeout(() => {
					this.countDown -= 1
					this.countDownTimer()
				}, 1000)

				this.requestOtp = false
			} else {
				this.countDown = timer
				this.requestOtp = true
			}
		},
		async handleClick() {
			if (!this.otpLoading && this.requestOtp) {
				const formData = new FormData()
				formData.append('mobile_number', this.mobile_number)

				await this.doRequest(formData)

				if (!this.otpMobileError && !this.otpTooManyRequestError && !this.otpError) {
					this.countDown = 60
					this.countDownTimer(60)

					this.snackbar = true

					this.$refs.otp.focus()
				} else {
					if (this.otpMobileError) {
						this.mobileNumberExistText = this.mobile_number
						this.$refs.mobile_number.validate()
					}

					if (this.otpTooManyRequestError) {
						this.tooManyAttemptsText = this.mobile_number
						this.$refs.mobile_number.validate()

						this.countDown = 1800
						this.countDownTimer(1800)
					}
				}
			}
		},
		async handleSubmit() {
			if (this.$refs.form.validate()) {
				if (!this.loading) {
					this.submit_loading = true

					const formData = new FormData()
					formData.append('first_name', this.firstname)
					formData.append('middle_name', this.middlename)
					formData.append('last_name', this.lastname)
					formData.append('source_of_income', this.source_of_income)
					formData.append('nature_of_work', this.nature_of_work)
					formData.append('nature_of_business', this.nature_of_business)
					formData.append('mobile_number', this.mobile_number)
					formData.append('birthdate', this.birthdate)
					formData.append('email_address', this.email_address)
					formData.append('password', this.password)

					const validIdImage = await this.compressImage(this.valid_id)
					if (validIdImage) {
						formData.append('valid_id', validIdImage)
					}

					const selfieImage = await this.compressImage(this.selfie)
					if (selfieImage) {
						formData.append('selfie', selfieImage)
					}

					formData.append('referral_type', this.referral_type)
					formData.append('sub_type', this.sub_type)
					formData.append('referral_code', this.referral_code)

					await this.doRegister(formData)

					if (!this.error && !this.registerOtpError && isObjectEmpty(this.errors)) {
						this.successDialog = true
					} else {
						if (this.registerOtpError) {
							this.otpErrorDesc = this.registerOtpError
							this.otpErrorText = this.otp
							this.$refs.otp.validate()
							this.$refs.otp.focus()
						}

						if (!isObjectEmpty(this.errors)) {
							let errorFocus = ''

							Object.entries(this.errors).forEach(([key, value]) => {
								if (value) {
									if (!errorFocus) {
										errorFocus = key
									}

									if (key == 'email_address') {
										this.emailAddressExistText = this.email_address
										this.stepper = 2
									}

									if (key == 'mobile_number') {
										this.mobileNumberExistText = this.mobile_number
										this.stepper = 1
									}
								}
							})

							this.$refs.form.validate()

							//this.$refs[errorFocus].focus()
						}
					}

					this.submit_loading = false
				}
			}
		},
		continueVerify() {
			this.$router.push({ name: 'Account', query: { kyc_dialog: true } })
		},
		continueGames() {
			this.$router.push({ name: 'Tutorial' })
		},
		setSnackbar() {
			this.snackbar = false
		},
		async getOTP() {
			if (this.stepper == 1) {
				this.resetErrorMessages()

				this.otp_loading = true

				const formData = new FormData()
				formData.append('mobile_number', this.mobile_number)
				formData.append('otp', this.otp)

				const response = await responseGet(new URLSearchParams(formData), 'register/validate_otp')

				switch (response.status) {
					case 200:
						{
							this.otp_text = response.data.data
							this.stepper = 2
						}
						break
					case 422:
						{
							this.validation_error_message = response.data.messages
						}
						break
				}
			}

			this.otp_loading = false
		},
		resetErrorMessages() {
			this.otp_error_message = ''
			this.validation_error_message = []
		},
		async compressImage(image) {
			return new Promise((resolve) => {
				new Compressor(image, {
					quality: 1,
					width: 800,
					success(result) {
						const compressedImage = new File([result], result.name, {
							type: result.type,
						})
						resolve(compressedImage)
					},
					error(err) {
						console.error('Image compression error:', err)
						resolve(null) // Return null if compression fails
					},
				})
			})
		},
		validIDOnFileChange(event) {
			if (event) {
				this.valid_id_url = URL.createObjectURL(event)
			}
		},
		validIDClearFile() {
			this.valid_id = null
			this.valid_id_url = null
		},
		selfieOnFileChange(event) {
			if (event) {
				this.selfie_url = URL.createObjectURL(event)
			}
		},
		selfieClearFile() {
			this.selfie = null
			this.selfie_url = null
		},
		redirectToLimitedAccess() {
			this.setDialogs('register')

			this.$router.push({
				name: 'ResponsibleGaming',
			})
		},
		isUnder21() {
			var today = new Date()
			var birthdateObj = new Date(this.birthdate)
			var age = today.getFullYear() - birthdateObj.getFullYear()
			var monthDiff = today.getMonth() - birthdateObj.getMonth()

			if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdateObj.getDate())) {
				age--
			}

			if (age < 21) {
				return 'Age under 21 years old is restricted.'
			} else if (age >= 21) {
				return true
			}
		},
		setPhotos(data) {
			if (data['blob']) {
				if (data['type'] == 'valid_id') {
					this.valid_id = data.blob
					this.valid_id_url = URL.createObjectURL(data['blob'])
				} else {
					this.selfie = data.blob
					this.selfie_url = URL.createObjectURL(data['blob'])
				}
			}
		},
		async verifyOTP() {
			this.resetErrorMessages()

			this.isFetchingOTP = true

			const formData = new FormData()
			formData.append('mobile_number', this.mobile_number)
			formData.append('otp', this.otp)

			const response = await responseGet(new URLSearchParams(formData), 'register/validate_otp')

			switch (response.status) {
				case 200:
					{
						this.otp_text = response.data.data
						this.otpSuccess = true
						this.isFetchingOTP = false
					}
					break
				case 422:
					{
						this.validation_error_message = response.data.messages
						this.otpSuccess = false
						this.isFetchingOTP = false
					}
					break
			}
		},
	},
	watch: {
		stepper(val) {
			if (val == 2) {
				this.$nextTick(() => {
					setTimeout(() => {
						this.$refs.firstname.focus()
					}, 300)
				})
			} else if (val == 3) {
				this.$nextTick(() => {
					setTimeout(() => {
						this.$refs.password.focus()
					}, 300)
				})
			}
		},
		birthdate_menu(val) {
			val && setTimeout(() => (this.birthdateActivePicker = 'YEAR'))
		},
		mobile_number(val) {
			if (val) {
				this.mobile_number = val.replace(/^0+/, '')
			}
		},
		source_of_income(val) {
			if (val != 1 || val != 2) {
				this.nature_of_work = '0'
				this.nature_of_business = '0'
			}
		},
	},
}
</script>

<style scoped>
.register .left-panel {
	background-image: url('../assets/img/login.webp');
	background-size: cover;
	background-position: top right;
	background-repeat: no-repeat;
}
</style>
